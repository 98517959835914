import { Box, Toolbar, Typography } from '@mui/material';
import React from 'react'
import Footer from '../components/Footer';
export default function ContactPage() {

    return (
        <Box height={'100vh'} display={'flex'} flexDirection={'column'}>
            <Toolbar disableGutters sx={{ mb: {lg: 6} }}/>
            <Box flexGrow={1}>
            <Typography sx={{width: '100%', display:'flex', justifyContent: 'center'}}>Contacto</Typography>
            </Box>
            <Footer/>
        </Box>
    )
}
