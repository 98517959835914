import React from "react";

function ShapeRightIcon({className , leftDireccion}) { 
  return (    
    <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="none"
    style={ leftDireccion && { transform: 'scaleX(-1)' }}
    viewBox="0 0 375 163"
    >
    <path
        fill="#fff"
        d="M136.354-13.47l38.631 105.827L-10-13.47h146.354zM337.47-3.79l56.654 97.375L193.4 21.97 337.47-3.79z"
    ></path>
    <path
        fill="#fff"
        d="M184.552 97.8l-97.79-55.936L296.375 3.379l-111.823 94.42z"
        opacity="0.7"
    ></path>
    </svg>
  );
}

export default ShapeRightIcon;