import React from 'react'
import { useNavigate } from 'react-router-dom';

import { Box, Toolbar, Typography } from '@mui/material';
import LogoClient from '../components/Icons/LogoClient';
import ButtonStyled from '../components/ButtonStyled';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/`);
  }

  return (
    <>
        <Box  display={'flex'} flexDirection={'column'}>
            <Box sx={{display:'flex', minHeight: '100vh', flexDirection:'column', alignItems:'center', justifyContent:'center', backgroundColor: '#303030', gap: '3.25rem' }}>
                <LogoClient customStyle={{width: 200, height: 60 }}/>
                <Typography sx={{fontSize:{xs: '1.75rem', md: '2.875rem'}, lineHeight: '3.5rem', color: 'white'}}> <b>ERROR 404</b> </Typography>
                <Typography sx={{fontSize:{xs: '1.75rem', md: '2.875rem'}, lineHeight: '3.5rem', color: 'white', textAlign: 'center' }}> Lo sentimos, la página que estas buscando no existe. </Typography>
                <Box>
                  <ButtonStyled text={'Ir al inicio'} handleClick={handleClick}/>
                </Box>
            </Box>
        </Box>
    </>
  )
}

export default NotFoundPage