import React from 'react'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import HomePage from '../pages/HomePage';
import NavbarPage from '../pages/NavbarPage';
import ProductsPage from '../pages/ProductsPage';
import BrandsPage from '../pages/BrandsPage';
import ApplicationsPage from '../pages/ApplicationsPage';
import MeasuresPage from '../pages/MeasuresPage';
import ContactPage from '../pages/ContactPage';
import ProductCategoryPage from '../pages/ProductCategoryPage';
import CustomizeProductPage from '../pages/CustomizeProductPage';
import RequestProductPage from '../pages/RequestProductPage';
import NotFoundPage from '../pages/NotFoundPage';
import RegisterPage from '../pages/RegisterPage';
import LoginPage from '../pages/LoginPage';
import CartPage from '../pages/CartPage';
import SuccessfulPayment from '../pages/SuccessfulPayment';
import OrdersPage from '../pages/OrdersPage';
import ProductDetailsPage from '../pages/ProductDetailsPage';

const router = createBrowserRouter([
    {
      path: "/",
      element: <NavbarPage/>,
      children: [
        {
          path: "/",
          element: <HomePage/>
        },
        {
          path: "/products",
          element: <ProductsPage/>,
        },
        {
          path: "/products/:type/:subCategoryId",
          element: <ProductCategoryPage/>
        },    
        {
          path: "/products/customize/:productId",
          element: <CustomizeProductPage/>
        },    
        {
          path: "/products/details/:productId",
          element: <ProductDetailsPage/>
        },    
        {
          path: "/products/request",
          element: <RequestProductPage/>
        },    
        {
          path: "/brands",
          element: <BrandsPage/>
        },
        {
          path: "/applications",
          element: <ApplicationsPage/>
        },
        {
          path: "/measures",
          element: <MeasuresPage/>
        },
        {
          path: "/contact",
          element: <ContactPage/>
        },
        {
          path: "/cart",
          element: <CartPage/>,
        },
        {
          path: "/orders",
          element: <OrdersPage/>,
        },

      ]
    },
    {
      path: "/user/:id",
      element: <RegisterPage/>,
    },
    {
      path: "/login",
      element: <LoginPage/>,
    },
    {
      path: "/successfulpayment",
      element: <SuccessfulPayment/>,
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ]);

const Router = () => {
  return (
    <RouterProvider router={router}/>
  )
}

export default Router;
