import axios from "axios";

const HTTP_STATUS = {
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    SERVER_ERROR: 500,
}

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_ROOT_API
});

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token !== null) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (err.response) {
        if (err.response.status === HTTP_STATUS.UNAUTHORIZED || err.response.status === HTTP_STATUS.FORBIDDEN ) {
          if (!originalConfig._retry){
            originalConfig._retry = true;
  
            try {
              const rs = await getRefreshToken();
              const { AccessToken } = rs.data;
              localStorage.setItem("token", AccessToken);
              originalConfig.headers.Authorization = `Bearer ${AccessToken}`;
              return axiosInstance(originalConfig);
            } catch (_error) {
              localStorage.clear();
              window.location.href = '/';
              if (_error.response && _error.response.data) {
                return Promise.reject(_error.response.data);
              }
              return Promise.reject(_error);
            }
          }else{
            localStorage.clear();
            window.location.href = '/';
            return Promise.reject(err);
          }
        } 
      }
      return Promise.reject(err);
    });

const getRefreshToken = () => {
    return axiosInstance.post('/users/login/refresh', {
        refreshToken: localStorage.getItem('refreshToken'),
        uid: localStorage.getItem('UID'),
        token: localStorage.getItem('token'),
    });
}


export default axiosInstance;