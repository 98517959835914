
import { useNavigate } from 'react-router-dom';
import { useState } from 'react'
import { TextField } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import { getProductsSearch } from '../services/productService';

const SearchBar = ({isLarge = false}) => {
  const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);

    const [debounceTimer, setDebounceTimer] = useState(null);


  const handleSearchChange = async (evt) => {
    const wordToSearch = evt.target.value;

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const newDebounceTimer = setTimeout(() => { 
      searchProduct(wordToSearch);
    }, 300);

    setDebounceTimer(newDebounceTimer);
  }

  const searchProduct = async (wordToSearch) => {
    if(!wordToSearch) {
      setOptions([]);
      return;
    }

    const result = await getProductsSearch(wordToSearch);
    setOptions(result.products);
  }

  const handleSelectProduct = (evt, newProduct) => {
    if(!newProduct) {
      return
    }

    const navigateTo = `/products/details/${newProduct._id}`;
      
    const newState = { 
      breadcrumbs: [
          {name: 'Inicio', route: '/', state: {}},
          {name: newProduct.productName, route: '', state: {}},
      ],
      productId: newProduct._id,
      product: newProduct,
      selectedVariant: {},
      pathTree: []
    }

    navigate(navigateTo, {state:{ ...newState }});

  }
  
    return (
      <Autocomplete
        sx={{
          width: isLarge ? {xs:'100%'} : {xs:'100%', lg:"13rem"},
          fontSize: 16,
          color: {lg:"white"},
          '& .MuiAutocomplete-endAdornment': {
            display: 'none', 
          }
        }}
        disablePortal
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
          setOptions([]);
        }}
        isOptionEqualToValue={(option, value) => option.productName === value.productName}
        getOptionLabel={(option) => option.productName}
        options={options}
        noOptionsText="sin resultados."
        filterOptions={(x) => x}
        onChange={ (evt, newValue) => handleSelectProduct(evt, newValue)}
        renderInput={(params) => (
          <>
            <TextField
              sx={{
                background: isLarge ? {xs:'#FFFF'} : {xs:'#FFFF', lg: 'inherit'},
                boxShadow: isLarge ? {xs: '1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)' } : {xs: '1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)', lg:'0'},
                borderRadius: isLarge ? {xs:'4px'} : {xs:'4px', lg:"8px"},
                borderColor: "white",
                border: isLarge ? 'white' : {lg: "solid 1px"},
                marginLeft: 0,
                ".MuiOutlinedInput-notchedOutline": { border: "none" },
                '& input::placeholder': {
                  color: '#A1A1A1',
                },
                "& .MuiInputBase-root input": {py:"0 !important"},
                "& .MuiInputBase-root": {
                  py:{xs: '0.6875rem', lg: '0.4rem'},
                  paddingLeft:{xs: '0.75rem'},
                  paddingRight:{xs: '1rem !important'},
                  fontSize: {xs: 12,lg:16},
                  width: "100%",
                  color: isLarge ? {} : {lg:"white"},
                }
              }}
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment:
                <SearchIcon />
              }}
              onChange={(handleSearchChange)}
              placeholder="Buscar Producto"
            />
          </>
        )}
      />
    );
}

export default SearchBar;