import { useContext, useState } from 'react'
import { Box, Typography } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { currencyFormat, getBasePrice, sumExtraPrice, truncateDecimal } from '../utils/utils';
import { UserContext } from '../context/UserContext';

const style = {
  rows: { display:'flex', gap: '0.625rem', justifyContent:'space-between', alignItems:'center' },
  column1: {color: '#000', fontSize: {xs: '0.875rem', lg: '1rem'}, fontWeight: '500'},
  column2: {color: '#000', fontSize: {xs: '0.875rem', lg: '1rem'}, fontWeight :'500'},
};

const ProductResume = ({itemCart, dollarPrice}) => {
  const [expanded, setExpanded] = useState(false);
  const { domain } = useContext(UserContext);

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };
  
  const getRealPrice = () => {
    return getBasePrice(itemCart.product, dollarPrice);
  }

  return (
    <Box sx={{display:'flex', flexDirection: 'column', padding: '1.875rem 1.25rem', borderRadius: '0.25rem', border: '1px solid #D9D9D9', background: '#FFF', gap: {xs: '1rem', lg:'1.88rem'} }}>
      <Box sx={{ display:'flex', gap: '1.5rem' }}>
        <Box sx={{width: '3.375rem', height: '4.25rem', display:'flex', justifyContent:'center', alignItems: 'center' }}>
          { !!itemCart.product.images.length &&
            <img
              style={{
                objectFit:'contain',
                height:'inherit',
                width:'inherit'
              }}
              alt=''
              src={`${domain}${itemCart.product.images[0].url}`}
              onError={(e) => {
                e.target.style.display = 'none';
                e.target.nextSibling.style.display = 'flex'
              }}
            />
          }
          <Box sx={{display: !!itemCart.product.images.length ? 'none' : 'flex' , alignItems:'center', justifyContent:'center', height:'100%'}}>
            <Typography sx={{fontSize: {xs:13, md:16}, textAlign: 'center' }}>Sin imagen</Typography>
          </Box>
        </Box>
        <Typography sx={{ fontSize:{xs: '1.125rem', lg: '1.5rem'}, fontWeight: 'bold', color:'#252323', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}>
          {itemCart.product.productName}
        </Typography>
      </Box>
      <Box sx={{height: '0.0625rem', width:'100%', background:'#FC581F'}}/>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: !expanded ? 0 : {xs:'1rem', lg:'1.88rem'}}}>
        <Box sx={{display: 'flex', py: '0.62rem', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer'}} onClick={toggleAccordion}>
          <Typography sx={{ fontSize: {xs: '1rem', lg:'1.125rem'}, fontWeight: 'bold', color: '#000' }}>Resumen de producto</Typography>
          <NavigateNextIcon sx={{transform: expanded ? 'rotate(270deg)': 'rotate(90deg)', fontSize:{xs:'2.2rem'}, color: '#303030'}}/>
        </Box>
        <Box sx={{ display:'flex', flexDirection:'column', gap: {xs:'0.75rem', lg: '0.62rem'}, overflow: expanded ?  'unset': 'hidden', height: expanded ? 'auto' : '0px', transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms', transitionDuration: '222ms' }}>
          <Box sx={style.rows}>
            <Typography sx={style.column1}> ID único de producto</Typography>
            <Typography sx={style.column2}>{itemCart.sku}</Typography>
          </Box>
          <Box sx={style.rows}>
            <Typography sx={style.column1}>Precio base</Typography>
            <Typography sx={style.column2}>{`${currencyFormat(itemCart.product.price * Number(dollarPrice))} MXN`}</Typography>
          </Box>
          <Box sx={style.rows}>
            <Typography sx={style.column1}>Personalización</Typography>
            <Typography sx={style.column2}>{`${currencyFormat(sumExtraPrice(itemCart.product.productVariants[0]) * Number(dollarPrice))} MXN`}</Typography>
          </Box>
          <Box sx={style.rows}>
            <Typography sx={style.column1}>Cantidad</Typography>
            <Typography sx={style.column2}>{`${itemCart.quantity} pcs.`}</Typography>
          </Box>
          <Box sx={style.rows}>
            <Typography sx={style.column1}>Total</Typography>
            <Typography sx={style.column2}>{`${currencyFormat(getRealPrice() * itemCart.quantity)} MXN`}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ProductResume