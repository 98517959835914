import { useContext, useEffect, useState } from 'react'
import { Box, Button, Divider, FormControl, FormLabel, Grid, IconButton, InputAdornment, OutlinedInput, TextField, Typography } from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { currencyFormat, getBasePrice, truncateDecimal } from '../utils/utils';
import { UserContext } from '../context/UserContext';

const style = {
    rows: { display:'flex', gap: '0.625rem', justifyContent:'space-between', alignItems:'center' },
    column1: {color: '#000', fontSize: '0.875rem'},
    column2: {color: '#000', fontSize: '0.875rem', fontWeight :'500'},
    quantityInput: { inputProps: { fontSize:{xs:'0.875rem' }, min: 0, max: 99} },
    quantityTextfield: {
        width: {xs: '25%', sm: '15%', md: '25%'},
        "& .MuiInputBase-root":{
            "&.Mui-focused fieldset": {
                borderColor: '#FC581F'
            }
        },
        "& .MuiInputBase-root input": { 
            "& .MuiInputBase-root input": { padding:'0.5rem 0.75rem !important'},
            textAlign:'left',
            height:'1em ',
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                'WebkitAppearance': 'none',
                margin: 0,
            },
        },
        "& input": { 
            fontSize:{xs:'0.875rem'}
        }
    },
    columnDesktop: {
        width: 'calc( (100% - 1.5rem) / 3 ) ',
        display: 'flex',
        alignItems: 'center',
        overflow:'hidden'
    }
};

const ItemCart = ({itemCart, itemCartStock, isLoading, dollarPrice, handleUpdate}) => {
    const [quantity, setQuantity] = useState(0);
    const [debounceTimer, setDebounceTimer] = useState(null);
    const { domain } = useContext(UserContext);

    useEffect(() => {
        setQuantity(itemCart.quantity);
    }, [itemCart])

    const handleQuantityChange = (event) => {
        let newValue = event.target.value;
        newValue = Number(event.target.value.replace(/^0+/, '') || 0);
        
        if( !/^[0-9]+$/.test(newValue.toString()) || newValue === quantity || newValue.toString().length > 2){
            return;
        }

        setQuantity(newValue);

        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }

        const newDebounceTimer = setTimeout(() => { 
            handleUpdate(itemCart.sku, !!newValue ? newValue : 1);
        }, 700);

        setDebounceTimer(newDebounceTimer);
    };

    const handleDelete = () => {
        handleUpdate(itemCart.sku, 0, true);
    };
    
  return (
    <>
        <Box sx={{ display:{xs:'flex', lg: 'none'}, flexDirection:'column', padding: '1.875rem 1.25rem', gap: '1rem' }}>
            <Box sx={{ display:'flex', gap: '1.5rem' }}>
                <Box sx={{width: '3.375rem', height: '4.98913rem', display:'flex', justifyContent:'center', alignItems: 'center' }}>
                    { !!itemCart.product.images.length &&
                        <img
                            style={{
                                objectFit:'contain',
                                height:'inherit',
                                width:'inherit'
                            }}
                            alt=''
                            src={`${domain}${itemCart.product.images[0].url}`}
                            onError={(e) => {
                                e.target.style.display = 'none';
                                e.target.nextSibling.style.display = 'flex'
                            }}
                        />
                    }
                    <Box sx={{display: !!itemCart.product.images.length ? 'none' : 'flex' , alignItems:'center', justifyContent:'center', height:'100%'}}>
                        <Typography sx={{fontSize: {xs:13, md:16}, textAlign: 'center' }}>Sin imagen</Typography>
                    </Box>
                </Box>
                <Box sx={{display:'flex', flexGrow: '1', gap: '1.25rem'}}>
                    <Box sx={{display:'flex', flexGrow: '1'}}>
                        <Typography sx={{ fontSize:'1.125rem', fontWeight: 'bold', color:'#252323', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}>
                            {itemCart.product.productName}
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton 
                            sx={{ padding: 0 }}
                            disabled={isLoading}
                            onClick={handleDelete}
                        >
                            <DeleteOutlineOutlinedIcon sx={{fontSize:'1.5rem', color:  isLoading ? 'gray' : '#303030'}}/>
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display:'flex', flexDirection:'column', gap: '0.75rem' }}>
                <Typography sx={{color: '#303030', fontSize: '0.875rem' }} > {`ID único: ${itemCart.sku}`} </Typography>
                <Box sx={style.rows}>
                    <Typography sx={style.column1}>PRECIO BASE</Typography>
                    <Typography sx={style.column2}>{`${currencyFormat(getBasePrice(itemCart.product, dollarPrice))} MXN`}</Typography>
                </Box>
                <Box sx={style.rows}>
                    <Typography sx={style.column1}>CANTIDAD</Typography>
                    <TextField 
                        sx={style.quantityTextfield}
                        size='small'  
                        placeholder='0 piezas'
                        name='quantity'
                        value={quantity}
                        disabled={isLoading}
                        // error={stockFormError}
                        onChange={handleQuantityChange}
                        InputProps={style.quantityInput}
                    />
                </Box>
                <Box sx={style.rows}>
                    <Typography sx={style.column1}>SUBTOTAL</Typography>
                    <Typography sx={style.column2}>{`${currencyFormat(getBasePrice(itemCart.product, dollarPrice) * itemCart.quantity)} MXN`}</Typography>
                </Box>
                {   !!itemCartStock && (
                        <Typography sx={{color: '#FC581F', fontSize: '0.875rem' }} > {`Entrega estimada: ${itemCartStock.stock >= quantity ? '1-5 días hábiles': `${itemCartStock.extraDeliveryTime}  ${Number(itemCartStock.extraDeliveryTime) > 1 || itemCartStock.extraDeliveryTime == '-' ? 'semanas' : 'semana'} ` }`} </Typography>
                    )
                }
            </Box>
        </Box>
        <Box sx={{ display:{xs:'none', lg: 'flex'}, gap:'0.75rem' }}>
            <Box sx={{ display:'flex', gap: '1.5rem', padding: '0.625rem 0.75rem', width: '47%' }}>
                <Box sx={{width: '3.375rem', height: {xs:'4.98913rem', lg:'7rem'}, display:'flex', justifyContent:'center', alignItems: 'flex-start' }}>
                    { !!itemCart.product.images.length &&
                        <img
                            style={{
                                objectFit:'contain',
                                height:'initial',
                                width:'inherit'
                            }}
                            alt=''
                            src={`${domain}${itemCart.product.images[0].url}`}
                            onError={(e) => {
                                e.target.style.display = 'none';
                                e.target.nextSibling.style.display = 'flex'
                            }}
                        />
                    }
                    <Box sx={{display: !!itemCart.product.images.length ? 'none' : 'flex' , alignItems:'flex-start', justifyContent:'center', height:'100%'}}>
                        <Typography sx={{fontSize: {xs:13, md:16}, textAlign:'center' }}>Sin imagen</Typography>
                    </Box>
                </Box>
                <Box sx={{display:'flex', flexDirection: 'column', flexGrow: '1', gap: '0.75rem'}}>
                    <Typography sx={{ fontSize:'1.25rem', fontWeight: 'bold', color:'#303030', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}>
                        {itemCart.product.productName}
                    </Typography>
                    <Typography sx={{color: '#303030', fontSize: '1rem' }} > {`ID único: ${itemCart.sku}`} </Typography>
                    {!!itemCartStock && (
                        <Typography sx={{color: '#FC581F', fontSize: '1rem' }} > {`Entrega estimada: ${itemCartStock.stock >= quantity ? '1-5 días hábiles': `${itemCartStock.extraDeliveryTime}  ${Number(itemCartStock.extraDeliveryTime) > 1 || itemCartStock.extraDeliveryTime == '-' ? 'semanas' : 'semana'} ` }`} </Typography>
                    )}
                </Box>
            </Box>
            <Box sx={{ display:'flex', gap:'0.75rem', width: '53%', height: 'fit-content' }}>
                <Box sx={style.columnDesktop}>
                    <Typography sx={style.column2}>{`${currencyFormat(getBasePrice(itemCart.product, dollarPrice))} MXN`}</Typography>
                </Box>
                <Box sx={style.columnDesktop}>
                    <TextField 
                        sx={{...style.quantityTextfield, width: '100%'}}
                        size='small'  
                        placeholder='0 piezas'
                        name='quantity'
                        value={quantity}
                        disabled={isLoading}
                        // error={stockFormError}
                        onChange={handleQuantityChange}
                        InputProps={style.quantityInput}
                    />
                </Box>
                <Box sx={style.columnDesktop}>
                    <Typography sx={style.column2}>{`${currencyFormat(getBasePrice(itemCart.product, dollarPrice) * itemCart.quantity)} MXN`}</Typography>
                </Box>
                <IconButton 
                    sx={{ padding: 0, width:'1.5rem' }}
                    disabled={isLoading}
                    onClick={handleDelete}
                >
                    <DeleteOutlineOutlinedIcon sx={{fontSize:'1.5rem', color:  isLoading ? 'gray' : '#303030'}}/>
                </IconButton>

            </Box>
        </Box>

    </>
  )
}

export default ItemCart