import { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import Footer from "../components/Footer";
import SearchBarTable from "../components/SearchBarTable";
import PageNavigation from "../components/PageNavigation";
import { getOrdersList } from "../services/orderService";
import OrderCard from "../components/OrderCard";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from "react-router-dom";

const OrdersPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [LastEvaluatedKey, setLastEvaluatedKey] = useState({});
  const navigate = useNavigate();
  const userId = localStorage.getItem("UID") || null;

  useEffect(() => {
    getTableData();
  }, [searchTerm, page, rowsPerPage]);

  const getTableData = async () => {
    setIsLoading(true);
    let { items, totalPages } = await getOrdersList(
      searchTerm,
      page,
      rowsPerPage,
      LastEvaluatedKey,
      userId
    );
    setTableData(items);
    setTotalPages(totalPages);
    setLastEvaluatedKey(LastEvaluatedKey);
    setIsLoading(false);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };

  const handleChangeSearchTerm = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
    setPage(1);
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { xs: "3rem", md: "1.5rem", lg: "2rem" },
          pt: {
            xs: "6.1825rem",
            sm: "6.5575rem",
            md: "6.4975rem",
            lg: "8.75rem",
          },
          px: { xs: "1.5625rem", md: "3.12rem", lg: "4.5rem", xl: "6.25rem" },
          pb: "5rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <NavigateNextIcon
              sx={{
                transform: "rotate(180deg)",
                ml: { xs: "0px" },
                fontSize: { xs: "1.5rem", md: "2rem" },
                color: "#FC581F",
              }}
            />
          </IconButton>
          <Typography
            sx={{
              color: "#303030",
              fontSize: { xs: "1.25rem", md: "1.5rem" },
              fontWeight: "bold",
            }}
          >
            Mis Pedidos
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
          <SearchBarTable
            onSearchTermChange={handleChangeSearchTerm}
            placeHolder={"Buscar por No. de Orden"}
          />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            {tableData.map((order, indx) => (
              <OrderCard key={indx} order={order} />
            ))}
          </Box>
          <PageNavigation
            rowsNumber={tableData.length || 0}
            page={page}
            rowsPerPage={rowsPerPage}
            totalPages={totalPages}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            isLoading={isLoading}
          />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default OrdersPage;
