
function HalfGearIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{width:"auto", height:"100%"}}
      fill="none"
      viewBox="0 0 68 128"
    >
      <path
        fill="#F8F8F8"
        d="M124.437 53.279h-18.294a41.913 41.913 0 00-4.619-11.003l12.963-12.852a4.486 4.486 0 00.986-4.914 4.515 4.515 0 00-.986-1.463l-8.779-8.7a4.538 4.538 0 00-3.207-1.322 4.533 4.533 0 00-3.208 1.321L86.331 27.2a42.57 42.57 0 00-11.088-4.572V4.494a4.5 4.5 0 00-1.341-3.179A4.57 4.57 0 0070.692 0H58.297a4.571 4.571 0 00-3.214 1.312 4.502 4.502 0 00-1.338 3.182v18.177a42.571 42.571 0 00-11.088 4.572L29.695 14.39a4.532 4.532 0 00-3.207-1.321 4.559 4.559 0 00-3.207 1.32l-8.78 8.702a4.503 4.503 0 00-1.331 3.188 4.475 4.475 0 001.332 3.188L27.453 42.32a42.23 42.23 0 00-4.607 11.003H4.551a4.581 4.581 0 00-3.218 1.323A4.513 4.513 0 000 57.84v12.32a4.476 4.476 0 001.338 3.183 4.542 4.542 0 003.213 1.312h18.295a41.887 41.887 0 004.607 11.003L14.502 98.51a4.51 4.51 0 00-1.329 3.194c0 1.197.478 2.345 1.329 3.193l8.78 8.701a4.534 4.534 0 003.206 1.321 4.553 4.553 0 003.207-1.321l12.963-12.852a42.545 42.545 0 0011.088 4.572v18.166a4.5 4.5 0 001.333 3.193A4.57 4.57 0 0058.298 128h12.415a4.595 4.595 0 003.212-1.329 4.522 4.522 0 001.34-3.187V105.23a42.613 42.613 0 0011.088-4.572l12.952 12.852a4.551 4.551 0 006.414 0l8.779-8.701a4.511 4.511 0 001.329-3.194 4.509 4.509 0 00-1.329-3.193L101.536 85.57a41.56 41.56 0 004.618-11.004h18.295a4.57 4.57 0 003.213-1.312A4.49 4.49 0 00129 70.072v-12.32a4.5 4.5 0 00-1.353-3.17 4.568 4.568 0 00-3.21-1.303zM85.215 69.474a20.306 20.306 0 01-2.153 5.136 21.238 21.238 0 01-5.514 6.279 21.417 21.417 0 01-7.526 3.684 21.709 21.709 0 01-16.32-2.125 21.479 21.479 0 01-7.809-7.815 20.71 20.71 0 01-2.153-5.137 21.449 21.449 0 010-11.07 20.709 20.709 0 012.153-5.136 21.36 21.36 0 017.809-7.749 21.692 21.692 0 0110.753-2.85c3.775 0 7.483.982 10.754 2.85a21.415 21.415 0 017.809 7.749 20.306 20.306 0 012.153 5.136 21.447 21.447 0 010 11.07l.044-.022z"
      ></path>
    </svg>
  );
}

export default HalfGearIcon;