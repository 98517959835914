import React, { useContext, useEffect, useState } from "react";
import { Box, Divider, Typography, Link, Button } from "@mui/material";
import { currencyFormat, formatDate } from "../utils/utils";
import ChipLabel from "./ChipLabel";
import { orderStatus } from "../utils/enums";
import ButtonStyled from "./ButtonStyled";
import OrderProduct from "./OrderProduct";
import { cartBuyAgain } from '../services/userCartService'
import { useNavigate } from "react-router-dom";
import { UserDispatchContext } from "../context/UserContext";

const styles = {
  label: {
    fontSize: { xs: "0.75rem", lg: "0.875rem" },
    color: "#7C7B7B",
  },
};

const OrderCard = ({ order }) => {
  const navigate = useNavigate();
  const globalUserDispatchContext = useContext(UserDispatchContext);
  const [orderIdToRebuy, setOrderIdToRebuy] = useState(null)

  const boxesTrackingUrl = order.shipment
    .filter(box => ((!!box.label && !!box.label.attributes) && !!box.label.attributes.tracking_url_provider))
    .map(item => item.label.attributes.tracking_url_provider);

  const handleBuyAgain = async (cartId) => {
    setOrderIdToRebuy(cartId)
    cartBuyAgain(cartId)
      .then(res => {
        localStorage.setItem('userCart', JSON.stringify(res.data));
        globalUserDispatchContext({type: 'INIT_CART'})
        setOrderIdToRebuy(null)
        navigate('/cart')
      })
      .catch(err => {
        console.error(err)
      })
  }

  useEffect(() => {
    console.log(order)
  })

  return (
    <Box
      sx={{
        padding: "1.5rem 2rem",
        background: "white",
        borderRadius: "0.5rem",
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { xs: "1.24rem", md: "1.5rem" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: "0.73rem", md: "1rem" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "0.875rem",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                width: { xs: "100%", md: "auto" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: { xs: "space-between", md: "flex-start" },
                }}
              >
                <Typography
                  sx={{ fontSize: "1rem", fontWeight: "bold" }}
                >{`ID de Orden: ${order._id}`}</Typography>
                <Box>
                  <ChipLabel
                    label={orderStatus[order.status].label}
                    background={orderStatus[order.status].background}
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: "0.875rem" }}>
                <Typography sx={styles.label}>{"Pedido:"}</Typography>
                <Typography sx={styles.label}>
                  {formatDate(order.paidAt)}
                </Typography>
              </Box>
              {boxesTrackingUrl.map((url, indx) => (
                <Typography key={indx} sx={{fontSize: '0.875rem', color: '#303030', lineHeight: '1rem', fontWeight: '500' }}>
                  {`Caja ${indx+1}:`}
                  { 
                    <Link href={`${url}`} target="_blank" rel="noopener" sx={{fontSize: '0.875rem', color: '#2F69FF', lineHeight: '1.25rem', fontWeight: '500', textDecorationLine: 'underline', pl:'0.25rem'}}>
                        Link de Rastreo
                    </Link>
                  }
                </Typography>
              ))}
              {/* <Box sx={{ display: "flex", gap: "0.875rem" }}>
                <Typography sx={styles.label}>{"Llegada:"}</Typography>
                <Typography sx={styles.label}>
                  {"Entrega estimada de 1 -2 semanas"}
                </Typography>
              </Box> */}
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <Button
                variant='contained'
                disabled={orderIdToRebuy === order._id}
                onClick={() => handleBuyAgain(order._id)}
                sx={{
                    fontSize: '1rem',
                    fontWeight:'500',
                    backgroundColor: '#FC581F' ,
                    textTransform:'none',
                    padding: '0.75rem 1rem',
                    boxShadow: 0,lineHeight: '1.125rem',
                    letterSpacing: '0.01563rem',
                    '&:hover': { 
                        backgroundColor: '#FC581F'
                    }
                }}
              >
                Volver a comprar
              </Button>
              <Typography
                sx={{
                  fontSize: {
                    xs: "0.75rem",
                    lg: "0.875rem",
                    textAlign: "center",
                  },
                }}
              >{`Total: ${currencyFormat(order.totalPaid)} MXN`}</Typography>
            </Box>
          </Box>
          <Divider
            orientation="horizontal"
            sx={{ borderWidth: "1px", borderColor: "#FEDED2" }}
          />
        </Box>
        <Box sx={{display:"flex", gap: '1.5rem', px: {xs: '1.4375rem', md: '0rem'}, overflow: "auto" }}> 
          {order.items.map((item, indx) => (
            <OrderProduct key={indx} orderItem={item} dollarPrice={order.dollarPrice} />
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          gap: "0.625rem",
        }}
      >
        <Button
          variant='contained'
          disabled={orderIdToRebuy === order._id}
          onClick={handleBuyAgain}
          onLoad={orderIdToRebuy === order._id}
          sx={{
              fontSize: '1rem',
              fontWeight:'500',
              backgroundColor: '#FC581F' ,
              textTransform:'none',
              padding: '0.75rem 1rem',
              boxShadow: 0,lineHeight: '1.125rem',
              letterSpacing: '0.01563rem',
              '&:hover': { 
                  backgroundColor: '#FC581F'
              }
          }}
        >
          Volver a comprar
        </Button>
        <Typography
          sx={{ fontSize: "0.875rem", textAlign: "center" }}
        >{`Total: ${currencyFormat(order.totalPaid)} MXN`}</Typography>
      </Box>
    </Box>
  );
};

export default OrderCard;
