import {useState, useEffect, useContext } from 'react'
import { Box, Container, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';

import TitleText from '../components/TitleText'
import { getProductCustomize } from '../services/productService';
import { addItemToCart, getElementByPath } from '../utils/utils';
import InputSelect from '../components/productVariants/InputSelect';
import ProductResumeCard from '../components/productVariants/ProductResumeCard';
import CustomBreadcrumbs from '../components/CustomBreadcrumbs';
import Footer from '../components/Footer';
import { UserDispatchContext } from '../context/UserContext';
import { userCartAddItem } from '../services/userCartService';
import { useSnackbar } from 'notistack';
import { exchangeRatesByCode } from '../services/ExchangeRateService';


const styles = {
    stackGrid: {
        display: 'flex',
        flexDirection: 'column',
        gap:{ xs:'2rem', lg:'1.88rem' }
    },
    title: {
        display:{ xs: 'none', md:'block'},
        color: '#303030',
        fontSize: { md:'1.5625rem' },
        fontWeight: 700,
    }
}

const CustomizeProductPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    const globalUserDispatchContext = useContext(UserDispatchContext);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [pathTree, setPathTree] = useState([]);
    const [product, setProduct] = useState([]);
    const [variantsTree, setVariantsTree] = useState([]);
    const [characteristicListData, setCharacteristicListData] = useState([]);
    const [selectedVariant, setSelectedVariant] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [dollarPrice, setDollarPrice] = useState(0);
    
    useEffect(() => {
        getData();
        getExchangeRateByCode();
    }, [])
    
    const getData = async () => {
        const routeSpliced = pathname.split('/');
        const productId = routeSpliced[routeSpliced.length-1];
        const result = await getProductCustomize(productId);
        if(!result.product || !Object.keys(result.product).length){
            navigate(`${pathname}/not-found`);
            return;
        }
        setProduct(result.product);
        setVariantsTree(result.variantsTree);
        updateTreeList(result.variantsTree, [], result.product.productVariants);
    };

    const getExchangeRateByCode = async () => {
        setIsLoading(true);
        let { message, result, exchangeRate } = await exchangeRatesByCode("USD");
        if (result) {
          setDollarPrice(exchangeRate.exchangeRate);
          setIsLoading(false);
        } else {
            setDollarPrice(0);
            enqueueSnackbar(message, {
                variant: "error",
            });
        }
    };

    const updateTreeList = (tree, path, productVariants) => {
        const result = getInputSelect(tree, 0, path);
        setPathTree(result.path);
        setCharacteristicListData(result.elements);

        const element = getElementByPath(result.path,tree, productVariants);
        setSelectedVariant(element);
    }

    const getInputSelect = (tree, lvl, path ) => {
        if(!tree.length) {
            return {elements: [], path: []};
        }

        const leafNumber = !!path.length ? path[0] : 0;
        let result = getInputSelect(tree[leafNumber].children, lvl+1,path.filter((item,index) => index > 0));
        const name = tree[0].characteristicName;
        const values = tree.map(item => item.value);
        return {
            elements: [
                {
                    key: lvl,
                    level: lvl,
                    textTitle: name,
                    options: values,
                    defaultValue: leafNumber,
                }, 
                ...result.elements
            ],
            path: [leafNumber ,...result.path],
        };
        
    }

    const handleOnchange = (title, value, level, indexOption) => {        
        let newPathTree = pathTree.filter((element, index) => index < level);
        newPathTree.push(indexOption);
        updateTreeList(variantsTree, newPathTree, product.productVariants);
    }

    const addToCart = async (redirect = false) => {
        const newProduct = {...product, productVariants: [selectedVariant]};
        const newCart = addItemToCart(newProduct);
        try {
            const userId = localStorage.getItem('UID');
            if(userId && !isLoading){
                setIsLoading(true);
                const result = await userCartAddItem(newCart);
                setIsLoading(false);
                if(result.result){
                    if(!newCart._id){
                        newCart._id = result.userCartId;
                    }
                    if(!redirect){
                        enqueueSnackbar(result.message, {
                            variant: 'success'
                        });
                    }
                }else{
                    enqueueSnackbar(result.message, {
                        variant: 'error'
                    });
                    return;
                }
            }else if(!redirect){
                enqueueSnackbar('articulo agregado al carrito.', {
                    variant: 'success'
                });
            }
            globalUserDispatchContext({
                type: 'ADD',
                userCart: newCart
            });
            if(redirect){
                navigate('/cart');
            }
            
        } catch (error) {
            console.log(error);
            enqueueSnackbar('ocurrio un problema al agregar al carrito', {
                variant: 'error'
            });
        }
    };


    return (
        <Box minHeight={'100vh'} display={'flex'} flexDirection={'column'} sx={{backgroundColor:'#F8F8F8'}}>
            <Container maxWidth="xl" sx={{ display:'flex', flexDirection:'column', flexGrow: 1, width:'100%', px:{xs:'1.25rem', md:'3.12rem', lg:'6.25rem'}, pb:{xs:'3rem'}, pt:{xs: '4.94rem', md: '5.25rem', lg:'8.13rem'} }}>
                <Box>
                    <TitleText title={'Personalización'} customStyles={{display:{xs: 'flex', md:'none'}, pb:'0.62rem'}}/>
                </Box>
                <Box sx={{width:'100%', display:'flex', flexDirection: {xs: 'column', md:'row'}, gap: {xs: '1.88rem', md:'1.5rem', lg:'1.91rem'} }}>
                    <Box  sx={{
                        width: '100%',
                         ...styles.stackGrid, order:{xs: 2, md: 1} }}>
                        <Typography sx={styles.title}>
                            Personalización
                        </Typography>
                        {!!characteristicListData.length && characteristicListData.map(item => 
                            (<InputSelect key={item.key} level={item.level} textTitle={item.textTitle} options={item.options} defaultValue={item.defaultValue} handleOnchange={handleOnchange}/>)
                        )}
                    </Box>
                    <Box  sx={{ width: '100%', order:{xs: 1, md: 2} }} >
                        {
                            (!!product && !!selectedVariant) 
                            && <ProductResumeCard product={product} selectedVariant={selectedVariant} dollarPrice={dollarPrice} buttonText1={'Agregar al carrito'} buttonText2={'Continuar'} handleButton1={() => addToCart()} customStyle1={{color: '#FC581F'}} handleButton2={() => addToCart(true)}/>
                        }

                    </Box>
                </Box>
            </Container>
            <Footer/>
        </Box>
    )
}

export default CustomizeProductPage