import React from "react";

function FileIcon() {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.51576 15.5588V9.39517L2.13075 6.27879V12.4424L7.51576 15.5588ZM8.89652 15.5588L14.2815 12.4424V6.26498L8.89652 9.39517V15.5588ZM7.51576 17.1481L1.44038 13.6448C1.22407 13.5204 1.05492 13.3522 0.932945 13.1403C0.810981 12.9284 0.75 12.6981 0.75 12.4494V5.55325C0.75 5.30451 0.810981 5.07418 0.932945 4.86227C1.05492 4.65037 1.22407 4.48223 1.44038 4.35785L7.51576 0.854536C7.73208 0.730158 7.9622 0.667969 8.20614 0.667969C8.45008 0.667969 8.68021 0.730158 8.89652 0.854536L14.9719 4.35785C15.1882 4.48223 15.3574 4.65037 15.4793 4.86227C15.6013 5.07418 15.6623 5.30451 15.6623 5.55325V12.4494C15.6623 12.6981 15.6013 12.9284 15.4793 13.1403C15.3574 13.3522 15.1882 13.5204 14.9719 13.6448L8.89652 17.1481C8.68021 17.2724 8.45008 17.3346 8.20614 17.3346C7.9622 17.3346 7.73208 17.2724 7.51576 17.1481ZM11.5752 6.24425L13.5152 5.12485L8.20614 2.04993L6.25926 3.17626L11.5752 6.24425ZM8.20614 8.21357L10.1807 7.05962L4.85777 3.98472L2.88326 5.12485L8.20614 8.21357Z"
        fill="#FC581F"
      />
    </svg>
  );
}

export default FileIcon;
