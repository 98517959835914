import { Box, Grid, Typography } from "@mui/material"
import LogoClient from "./Icons/LogoClient"
import WhiteCartIcon from "./Icons/WhiteCartIcon";
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
        <Box sx={{display:{xs:'none',lg:'flex'}, backgroundColor: '#303030', px: '6.25rem', py:'3.12rem'}}>
          <Box sx={{display:'flex', width:'100%', gap: '8.88rem'}} >
            <Box >
              <LogoClient customStyle={{width:"190", height: "50", viewBox:"0 0 150 40"}}/>
              <Typography sx={{color:'white', fontSize: { lg: '0.9375rem', xl: '18px'}, fontWeight:'600', pt:'2.88rem' }}>
                Copyright © 2024
              </Typography>
              <Typography sx={{color:'white', fontSize: { lg: '0.9375rem', xl: '18px'}, fontWeight:'400', pt:'1.25rem' }}>
                Mediciontotal.com, Inc. o sus afiliados
              </Typography>
            </Box>
            <Box sx={{display: 'flex', flexGrow: 1, flexDirection: 'column', gap: '1.5625rem', flex: '1 0 0' }}>
              <Box sx={{height:'5.5rem', display:'flex', gap: '1.25rem'}}>
              <Box sx={{display: 'flex', gap: '1rem', width: '100%'}}>
                <NavLink style={{ textDecoration: 'none' }} to={'/products'}>
                  <Typography sx={{color:'white', fontSize: { lg: '1rem'}, '&:hover': {color: '#FC581F'} }}>
                    <b>Productos</b>
                  </Typography>
                </NavLink>
                <NavLink style={{ textDecoration: 'none' }} to={'/applications'}>
                  <Typography sx={{color:'white', fontSize: { lg: '1rem'}, '&:hover': {color: '#FC581F'} }}>
                    <b>Aplicaciones</b>
                  </Typography>
                </NavLink>
                <NavLink style={{ textDecoration: 'none' }} to={'/measures'}>
                  <Typography sx={{color:'white', fontSize: { lg: '1rem'}, '&:hover': {color: '#FC581F'} }}>
                    <b>Magnitudes</b>
                  </Typography>
                </NavLink>
                </Box>
                <Box sx={{width: '100%'}}>
                  <Typography sx={{fontSize: '1.25rem', fontWeight:600, color:"white"}}> Contacto</Typography>
                  <Typography sx={{fontSize: '1rem', color:"white", pt: '0.5rem'}}> ventas@mediciontotal.com </Typography>
                </Box>
              </Box>
              <Box sx={{height:' 0.0625rem', width:'100%', background: '#FC581F' }}/>
              <Box sx={{width: '100%', display:"flex", justifyContent:"space-between" }}>
                <Typography sx={{color: 'white',fontSize: '1rem'}}>Condiciones de uso</Typography>
                <Typography sx={{color: 'white',fontSize: '1rem'}}>Aviso de privacidad</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{backgroundColor: '#FC581F', height: {xs: 50, lg: 43}, width: '100%', display: {xs:'flex', lg: 'none'},  justifyContent: {xs:'center', lg:'auto'}}}>
          <Box sx={{display: {xs:'none', lg:'flex'}, alignItems: 'center', width:{lg: '50%'}}}>
            <Typography sx={{color :'white', pl:{xs: 1.75, lg: 12}, display: 'flex', alignItems: 'center', fontSize: { xs: '10px', sm: '10px', md: '14px', lg: '16px', xl: '18px'}}}>
              <b>Condiciones de uso</b>
            </Typography>
            <Typography sx={{color :'white', pl:{xs: 1.75, lg: 3}, display: 'flex', alignItems: 'center', fontSize: {lg: '16px', xl: '18px'}}}>
              <b>Aviso de privacidad</b>
            </Typography>
          </Box>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent:{lg:'flex-end'}, width:{lg: '50%'}}}>
            <Box sx={{display: {lg: 'none'}}}>
              <WhiteCartIcon/>
            </Box>
            <Typography sx={{color :'white', pl:{xs: 1.75, lg: 0}, pr:{lg:8}, display: 'flex', alignItems: 'center', fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px', xl: '18px'}}}>
              © 2022-2023, MedicionTotal.com, Inc. o sus afiliados
            </Typography>
          </Box>
        </Box>
    </footer>  
  )
}

export default Footer;