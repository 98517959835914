import { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { NavLink } from 'react-router-dom';
import { Box, Divider, Stack, Typography } from '@mui/material';
import LogoOrange from './Icons/LogoOrange';
import LoginIcon from '@mui/icons-material/Login';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AppsIcon from '@mui/icons-material/Apps';
import SpeedIcon from '@mui/icons-material/Speed';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import LogoutIcon from '@mui/icons-material/Logout';

const IconsList  = {
  ShoppingCartIcon,
  LocalOfferIcon,
  AppsIcon,
  SpeedIcon,
  ContactPageIcon
};

const NavbarMobile = ({menuList}) => {
  
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if(!!localStorage.getItem('UID')){
      setIsLoggedIn(true);
    }
  }, [])

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const logOut = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Box sx={{display:{lg:'none'}}}>
        <IconButton
          size="small"
          color="inherit"
          onClick={handleToggleMenu}
        >
          <MenuIcon/>
        </IconButton>
        <Drawer
          anchor="top"
          sx={{display:{lg:'none'}}}
          PaperProps={{ sx: {height: '100%', backgroundColor: 'rgba(48, 48, 48, 0.97)'} }}
          open={isMenuOpen}
          onClose={handleToggleMenu}
        >
          <Box sx={{display: 'flex', justifyContent: 'end'}}>
            <IconButton
              onClick={handleToggleMenu}
            >
              <CloseOutlinedIcon fontSize='large' sx={{ color: '#FC581F' }} />
            </IconButton>
          </Box>
          <Box sx={{px: {xs:'18%', sm:'35%' }, height:'100%', display:'flex', flexDirection: 'column'}}>
            <Box sx={{display:'flex', justifyContent: 'center'}}>
              <NavLink style={{ textDecoration: 'none' }} to={'/'}  onClick={handleToggleMenu}>
                <LogoOrange/>
              </NavLink>
            </Box>
            <Divider sx={{borderColor: 'white', pt: 2.5 }}/>
            <Stack sx={{display:'flex', flexGrow: 1, py: '2.5rem', gap: '2.5rem'}} direction="column" alignItems="center">
              {menuList.map(({name, icon, route}) => {
                const Icon = IconsList[icon];
                return (
                  <NavLink key={name} style={{ textDecoration: 'none' }} to={route}  onClick={handleToggleMenu}>
                    <Box alignItems={'center'} display={'flex'} flexDirection={'column'}>
                        <Icon fontSize='large' sx={{ color: 'white' }} />
                        <Typography color="white">{name}</Typography>
                    </Box>
                  </NavLink>
                )
              })}
              { isLoggedIn && 
                (<NavLink style={{ textDecoration: 'none' }} to={'/orders'}  onClick={handleToggleMenu}>
                  <Box alignItems={'center'} display={'flex'} flexDirection={'column'}>
                    <ListAltRoundedIcon fontSize='large' sx={{ color: 'white' }} />
                    <Typography color="white">Mis pedidos</Typography>
                  </Box>
                </NavLink>
              )}
            </Stack>
            <Divider sx={{borderColor: 'white'}}/>
            <Box alignItems={'center'} display={'flex'} flexDirection={'column'} paddingBottom={5} pt={2}>
            {
              isLoggedIn
              ? (
                <>
                  <IconButton onClick={logOut}>
                    <LogoutIcon fontSize='large' sx={{ color: 'white' ,rotate: '180deg' }}/>
                  </IconButton>
                  <Typography color="white">Cerrar Sesión</Typography>
                </>
              )
              : (
                <NavLink style={{ textDecoration: 'none', display: 'flex', flexDirection: 'column' }} to={'/login'} >
                  <IconButton>
                    <LoginIcon fontSize='large' sx={{ color: 'white' }} />
                  </IconButton>
                  <Typography color="white">Iniciar Sesión</Typography>
                </NavLink>
              )
            }
            </Box>
          </Box>
        </Drawer>
    </Box>
  );
};

export default NavbarMobile;
