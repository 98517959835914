import { useState } from 'react'
import { Box, Button, Divider, FormControl, FormLabel, Grid, IconButton, InputAdornment, OutlinedInput, TextField, Typography } from '@mui/material'
import LogoClient from '../components/Icons/LogoClient'
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import ButtonStyled from '../components/ButtonStyled';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { login } from '../services/userService';
import ShapeRightIcon from '../components/Icons/ShapeRightIcon';

const formLabelStyle = {color: '#FFF', fontWeight:'bold', fontSize:{xs: '1rem', display: 'flex'}, pb:'0.2rem' };
const inputStyle = {sx: { fontSize:{xs: '1rem', md: '1rem'}, background:'#353535', borderRadius: '0.25rem', color:'rgba(255, 255, 255, 0.50)' }};
const formControlStyle = {
    '& .MuiOutlinedInput-root': {
        borderRadius: '0.25rem',
        backgroundColor:'#353535',
        "&.Mui-focused fieldset": {
            borderColor: '#FC581F'
        },
    },
    '& .MuiSvgIcon-root':{
        color: 'white'
    },
    '& .MuiOutlinedInput-root fieldset':{
        borderColor: '#BEBDBD',
    }
};

const defaultErrorValues = {emailError: false, passwordError: false};
const requiredValues = ['email','password'];
const errorValues = {
    emailError: 'Email',
    passwordError: 'Contraseña',
};

const LoginPage = () => {
    const [userForm, setUserForm] = useState({
        email: '',
        password: '',
    });
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState(defaultErrorValues);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { email,  password } = userForm;
    const { emailError, passwordError } = errors;

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const updateForm = (e) => {
        let inputName = e.target.name;
        let value =  e.target.value;
    
        let NewValue = {[inputName]: value}
        setUserForm({
            ...userForm,
            ...NewValue
        });
        
        errors[`${inputName}Error`] && setErrors({ ...errors, [`${inputName}Error`]: false });
    };

    const validateForm = () => {
        let newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        requiredValues.forEach( key => {
          if(key == 'email'){
            newErrors[`${key}Error`] = !userForm[`${key}`] || !emailRegex.test(userForm[`${key}`]);
          } else {
            newErrors[`${key}Error`] = !userForm[`${key}`];
          }
        });
      
        for (const [key, value] of Object.entries(newErrors)) {
            if (value) {
                enqueueSnackbar(`Campo ${errorValues[key]} incorrecto.`, {
                    variant: 'error'
                });

                setErrors({
                    ...errors,
                    ...newErrors
                });
                return false;
            }
        }
      
        return true;
    };

    const OnSubmitForm = async () => {
        if(isLoading){
          return;
        }
    
        if(!validateForm()){
          return;
        }
        
        setIsLoading(true);
        const result = await login(userForm);
        setIsLoading(false);
        if(result.AccessToken){
            localStorage.clear();
            localStorage.setItem('token', result.AccessToken);
            localStorage.setItem('refreshToken', result.RefreshToken);
            localStorage.setItem('UID', result.uid);
            localStorage.setItem('name', result.name);
            if(!!result.userCart){
                localStorage.setItem('userCart', JSON.stringify(result.userCart));
            }
            navigate(-1);
        }else{
            setErrors({
                emailError: true,
                passwordError: true
            });
            setUserForm({
                ...userForm,
                password: ''
            });
            enqueueSnackbar(result.message, {
                variant: 'error',
                autoHideDuration: 3000
            });
        }
    };


    return (
        <>
            <Box sx={{position: 'relative', width: '100%', background: 'black', minHeight:'100vh', display:'flex', flexDirection:'column', justifyContent:{md:'center'}}}>
                <Box sx={{
                    position:'absolute', top: 0, left: {xs:0, lg:'0' }, display: {xs:'none', md:'block'},
                    width: {xs:'100%', md: '37%', lg:'40%'}, height: '100%', zIndex:'0'
                }}>
                    <ShapeRightIcon leftDireccion={true}/>
                </Box>
                <Box sx={{
                    position:'absolute', top: 0, right: {xs:0, lg:'0' }, overflow:'hidden',
                    width: {xs:'100%', md:'37%', lg: '40%'}, height: '100%', zIndex:'0'
                }}>
                    <ShapeRightIcon/>
                </Box>
                <Box sx={{
                    display:'flex', flexDirection:'column', alignItems:'center', pt:{xs:'30%', md:0},
                    px: {xs: '1.5rem', sm:'3rem', md:'7.75rem', lg: '20rem', xl: '28.94rem'}
                }}>
                    <Box sx={{width: {xs:'12.0625rem', md:'15.125rem'} }}>
                        <LogoClient customStyle={{height:'100%', width: '100%'}}/>
                    </Box>
                    <Box width={'100%'} pb={'3rem'} pt={{xs: '2.23rem', md:'5.3rem'}}>
                        <Box sx={{px: {xs: '0.91rem', sm:'5rem', md:'9rem', lg:'5rem', xl: '9rem'} }}>
                            <Typography sx={{fontSize: {xs:'1.75rem', md:'2rem'}, fontWeight:'bold', color:'#fff', textAlign:'center', pb:'3rem' }}>Inicio de sesión</Typography>
                            <Grid container rowSpacing={{xs:'1.75rem',}} columnSpacing={{xs:'0rem'}}>
                                <Grid item xs={12}>
                                    <FormControl variant="standard" fullWidth sx={formControlStyle}>
                                        <FormLabel sx={formLabelStyle}>
                                            CORREO ELECTRÓNICO:
                                        </FormLabel>
                                        <TextField 
                                            inputProps={inputStyle}
                                            error={emailError}
                                            placeholder='Escribe tu correo electrónico'
                                            size='small'
                                            name='email'
                                            value={email}
                                            onChange={updateForm}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="standard" fullWidth sx={formControlStyle} >
                                        <FormLabel focused={false} sx={formLabelStyle}>
                                            CONTRASEÑA:
                                        </FormLabel>
                                        <OutlinedInput
                                            inputProps={inputStyle}
                                            fullWidth
                                            size="small"
                                            type={showPassword ? 'text' : 'password'}
                                            name='password'
                                            value={password}
                                            placeholder='********'
                                            onChange={updateForm}
                                            error={passwordError}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Box sx={{pt:'1.75rem'}}>
                                <ButtonStyled text={'Iniciar sesión'}  handleClick={OnSubmitForm} customStyle={{padding: {xs: '1rem 2rem'}, width:'100%', fontWeight:'bold' }}/>
                            </Box>
                        </Box>
                    </Box>
                    <Divider sx={{width:'100%', borderWidth:'1px','::before, ::after':{borderColor:'#D9D9D9'} }}>
                        <Typography sx={{color:'#fff', fontSize: '0.875rem'}}>¿Eres nuevo en medición total?</Typography>
                    </Divider>
                    <Box width={'100%'}>
                        <Box pt={'1.75rem'} pb={'1rem'} sx={{px: {xs: '0.91rem', sm:'5rem', md:'9rem', lg:'5rem', xl: '9rem'}}}>
                            <Button variant="outlined"
                                sx={{
                                    textTransform:'none', padding: {xs: '1rem 2rem'}, width:'100%', color: '#FFF', 
                                    borderRadius: '0.25rem', border: '1px solid #FC581F', fontWeight:'bold',
                                    '&:hover': { 
                                        borderColor: '#FFF'
                                    },
                                }}
                                onClick={() => {navigate('/user/new')}}
                            >
                                Crea tu cuenta de medición total
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default LoginPage