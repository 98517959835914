export const MexicanStates = {
    AGU: 'Aguascalientes',
    BCN: 'Baja California',
    BCS: 'Baja California Sur',
    CAM: 'Campeche',
    CHH: 'Chihuahua',
    CHP: 'Chiapas',
    COA: 'Coahuila',
    COL: 'Colima',
    CDMX: 'Ciudad de México',
    DUR: 'Durango',
    GRO: 'Guerrero',
    GUA: 'Guanajuato',
    HID: 'Hidalgo',
    JAL: 'Jalisco',
    MEX: 'México',
    MIC: 'Michoacán',
    MOR: 'Morelos',
    NAY: 'Nayarit',
    NLE: 'Nuevo León',
    OAX: 'Oaxaca',
    PUE: 'Puebla',
    QUE: 'Querétaro',
    ROO: 'Quintana Roo',
    SLP: 'San Luis Potosí',
    SIN: 'Sinaloa',
    SON: 'Sonora',
    TAB: 'Tabasco',
    TAM: 'Tamaulipas',
    TLA: 'Tlaxcala',
    VER: 'Veracruz',
    YUC: 'Yucatán',
    ZAC: 'Zacatecas',
};

export const orderStatus = {
  paid : {label: 'Pagado', background: {bg: '#109C3E', bg2: '#CCFFCE'}},
  delivered : {label: 'Entregado', background: {bg: '#109C3E', bg2: '#CCFFCE'}},
  pending : {label: 'En Proceso', background: {bg: '#FC581F', bg2: '#FEDED2'}},
  cancelled : {label: 'Cancelado', background: {bg: '#D3D3D3', bg2: '#F4F4F4'}}
};
  