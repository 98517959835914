import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LogoClient from '../components/Icons/LogoClient';
import { NavLink } from 'react-router-dom';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

import SearchBar from '../components/SearchBar';
import WhiteCartIcon from '../components/Icons/WhiteCartIcon';
import Divider from '@mui/material/Divider';
import NavbarMobile from '../components/NavbarMobile';
import { Outlet, ScrollRestoration } from "react-router-dom";
import { useEffect, useState } from 'react';
import ShoppingCart from '../components/ShoppingCart';
import { Menu, MenuItem } from '@mui/material';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';

const menuList = [
  { name: 'Productos', icon: 'ShoppingCartIcon', route: '/products' },
  // { name: 'Marcas', icon: 'LocalOfferIcon', route: '/brands' },
  { name: 'Aplicaciones', icon: 'AppsIcon', route: '/applications' },
  { name: 'Magnitudes', icon: 'SpeedIcon', route: '/measures' },
  // { name: 'Contacto', icon: '  ContactPageIcon', route: '/contact' }
];

const NavbarPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');

  const [anchorEl, setAnchorEl] = useState(null);
  
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  useEffect(() => {
    if(!!localStorage.getItem('UID')){
      setIsLoggedIn(true);
      setUserName(localStorage.getItem('name'));
    }
  }, [])

  const logOut = () => {
    localStorage.clear();
    window.location.reload();
  };
  

  return (
    <>
      <AppBar position="fixed" sx={{ backgroundColor: 'inherit'}}>
        <Box sx={{ height: {xs:49, sm:55, lg:'5.5rem'}, pl:{ xs: '5%'}, pr:{ xs: '8%'}, backgroundColor: '#303030', }}>
          <Toolbar disableGutters sx={{display: 'flex', alignItems: 'center', minHeight: {xs:'49px', sm:'55px', lg:'5.5rem'}}}>
            <NavbarMobile menuList={menuList}/>
            <Divider orientation="vertical" variant='middle' flexItem sx={{borderColor:'White', pl:1, display:{lg:'none'}}} />
            <Box sx={{display: {lg: 'none'}, flexGrow: 1, pl: 1}}>
              <NavLink style={{ textDecoration: 'none' }} to={'/'}>
                <WhiteCartIcon/>
              </NavLink>
            </Box>
            <Box sx={{display:{xs: 'none', lg: 'block'}}}>
              <NavLink style={{ textDecoration: 'none' }} to={'/'}>
                <LogoClient customStyle={{cursor:'pointer'}}/>
              </NavLink>
            </Box>
            <Box sx={{display:{xs: 'none', lg: 'block'}, pl:'32px' }}>
              <SearchBar/>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' }, justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              {menuList.length ? menuList.map(({name, route}) => {
                return(<div key={name}>
                  <NavLink style={{ textDecoration: 'none' }} to={route} >
                    <Box
                      sx={{
                        height: '3rem',
                        color: 'white', 
                        display: 'flex', 
                        alignItems: 'center',
                        ml: {lg: 4, xl: 5}, 
                        '&:hover': {
                          color: '#FC581F'
                        },
                      }}
                    >
                      <b>{name}</b>
                    </Box>
                  </NavLink>
                </div>)        
              }) : <></>
            }
            </Box>

            <Box sx={{display: 'flex', alignItems: {xs:'flex-start', lg:'center'}, gap: '1rem'}}>
              <Divider orientation="vertical" variant='middle' flexItem sx={{borderColor:'White',  height: '2.1875rem', my: 0, display:{xs: 'none', lg:'block'} }} />
              {
                isLoggedIn
                ? <LogoutIcon sx={{ mt:{xs:0 }, cursor: 'pointer', rotate: '180deg' }} onClick={logOut} />
                : <NavLink style={{ textDecoration: 'none' }} to={'/login'} >
                  <AccountCircleOutlinedIcon sx={{pr: 1, pl: '1rem', mt:{xs:0 }, color:'white' }}/>
                </NavLink>
              }
              <Box sx={{display:{xs:'none', lg:'flex'}, flexDirection: 'column'}}>
                <Typography variant="subtitle1" color="white" lineHeight={1}>
                  Bienvenido
                </Typography>
                {
                  isLoggedIn
                  ? (
                    <>
                      <Box sx={{ display:'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem', cursor: 'pointer' }} onClick={handleClick}>
                        <Typography sx={{fontWeight: 600}} variant="subtitle1" color="white" lineHeight={1} >
                          {userName}
                        </Typography>
                        <KeyboardArrowDownRoundedIcon sx={{ transition: 'transform 0.3s', transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}/>
                      </Box>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        <MenuItem  onClick={handleClose}> 
                          <NavLink to={'/orders'} style={{textDecoration: 'none', color: 'black',display: 'flex', gap:'0.3125rem', fontSize: '0.875rem', alignItems:'center'}}>
                            <ListAltRoundedIcon/> Mis pedidos
                          </NavLink>
                        </MenuItem>
                      </Menu>
                    </>
                  )
                  : ( <NavLink style={{ textDecoration: 'none' }} to={'/login'} >
                      <Typography sx={{fontWeight: 600}} variant="subtitle1" color="white" lineHeight={1}>
                        Inicia Sesión
                      </Typography>
                    </NavLink>
                  )
                }
              </Box>
              <ShoppingCart/>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
      <Outlet/>
      <ScrollRestoration />
    </>
  );
}
export default NavbarPage