import axios from '../utils/axios';

const SERVICE_URL = '/products';

export const getProductsSearch = async (
    wordToSearch
) => {
    try {
        let response = await axios.post(`${SERVICE_URL}/search`, { wordToSearch });
        return response.data;
    } catch (error) {
        return error;
    }
}

export const getProductsByCategoryId = async ( categoryId, from, brand ) => {
    try {
        let response = await axios.post(`${SERVICE_URL}/bycategory`, { categoryId, from, brand });
        return response.data;
    } catch (error) {
        return error;
    }
}

export const getProductCustomize = async (
    productId
) => {
    try {
        let response = await axios.post(`${SERVICE_URL}/customize`, { productId });
        return response.data;
    } catch (error) {
        return {product: null, variantsTree: []};
    }
}

export const getProductById = async (
    productId
) => {
    try {
        let response = await axios.post(`${SERVICE_URL}/id`, { id: productId });
        return response.data;
    } catch (error) {
        return {product: null, variantsTree: []};
    }
}
