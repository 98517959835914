import  { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export const formatNumberToCurrency = (number) => {
  const numberWithCommas = Number(number).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const formattedNumber = `$${numberWithCommas}`;
  return formattedNumber;
};

export const currencyFormat = (number) => {
  const numberWithCommas = ((Math.trunc(number*100)/100).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const formattedNumber = `$ ${numberWithCommas}`;
  return formattedNumber;
};

export const formatDate = (date) => {
  return moment(date).format('DD/MM/YY');
};

export function truncateString(str, limit) {
  if (limit > 3){
    limit = limit-3
  }
  if (str.length > limit) {
    return str.substring(0, limit) + '...';
  }
  return str;
}

export function truncateDecimal(number) {
  return Number(Number(number).toFixed(2));
}

export const delay = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getElementByPath(path, processedJson, originalJson) {
  const targetCharacteristics = [];

  for (let i = 0; i < path.length; i++) {
    const index = path[i];
    

    if (processedJson && processedJson.length > index) {
      const currentNode = processedJson[index];
      targetCharacteristics.push({
        characteristicName: currentNode.characteristicName,
        value: currentNode.value,
      });
      processedJson = currentNode.children;
    } else {
      console.error(`Invalid path at index ${i}. Path does not exist.`);
      return null;
    }
  }

  const elementInOriginalJson = findElementByCharacteristics(originalJson, targetCharacteristics);

  return elementInOriginalJson;
}

export function findElementByCharacteristics(json, targetCharacteristics) {
  for (let i = 0; i < json.length; i++) {
    const product = json[i].characteristics;

    if (areCharacteristicsEqual(product, targetCharacteristics)) {
      return json[i];
    }
  }
  return null;
}

function areCharacteristicsEqual(productCharacteristics, targetCharacteristics) {
  if (productCharacteristics.length !== targetCharacteristics.length) {
    return false;
  }

  for (let i = 0; i < productCharacteristics.length; i++) {
    const productCharacteristic = productCharacteristics[i];
    const targetCharacteristic = targetCharacteristics[i];

    if (
      productCharacteristic.characteristicName !== targetCharacteristic.characteristicName ||
      productCharacteristic.value !== targetCharacteristic.value
    ) {
      return false;
    }
  }

  return true;
}

export function sumExtraPrice(json) {
let totalExtraPrice = 0;

if (json && json.characteristics && Array.isArray(json.characteristics)) {
  json.characteristics.forEach((characteristic) => {
    const extraPrice = parseFloat(characteristic.extraPrice);
    if (!isNaN(extraPrice)) {
      totalExtraPrice += extraPrice;
    }
  });
}

return totalExtraPrice;
}

export const getBasePrice = (product, dollarPrice=0) => {
  const productPrice = truncateDecimal(Number(product.price) * Number(dollarPrice));
  const totalExtraPrice = truncateDecimal(sumExtraPrice(product.productVariants[0]) * dollarPrice);
  return truncateDecimal(totalExtraPrice + productPrice);
}


export const addItemToCart = (product) => {
  const cartData = JSON.parse(localStorage.getItem('userCart')) || null;
  let newCart = null;
  if(!cartData){
    const userId = localStorage.getItem('UID') || '';
    const cartItem = getCartItem(product);
    newCart = { userId: userId, items: [cartItem]}
  }else{
    const skuProduct = !!product.productVariants.length ? product.productVariants[0].sku : product.code;
    const productIndex = cartData.items.findIndex(item => item.sku === skuProduct)
    if(productIndex >= 0){
        cartData.items[productIndex].quantity += 1;
        newCart = cartData;
    }else{
      const cartItem = getCartItem(product);
      cartData.items.push(cartItem);
      newCart = cartData;
    }
  }
  return newCart;
}

export const getCount = (cart) => {
  return cart ? cart.items.reduce((total, item) => total + Number(item.quantity), 0) : 0;
}

export const getCartItem = (product) => {
  return { 
    product: product, 
    sku: !!product.productVariants.length ? product.productVariants[0].sku : product.code,
     quantity: 1 
  }
}