import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const tableStyle = {
  pagination:{
    fontSize: '0.875rem',
    color: '#303030'
  },
  iconPagination: {
    padding: 0,
    color:'#303030'
  },
  SelectOptions:{
    fontSize: {xs:'0.875rem', md:'1rem'}
  },
  pageNumber: {
    padding: {xs:'0.469rem 3.217rem', md: '0.688rem 1.155rem'},
    borderRadius: '0.25rem',
    border: '1px solid #D9D9D9',
    background: '#FFFEFC',
    fontSize:'1rem'
  }
};

export default function PageNavigation({
  rowsNumber = 0,
  page, 
  rowsPerPage,
  totalPages,
  onPageChange,
  onRowsPerPageChange,
  isLoading
}) {

  const [rows, setRows] = React.useState(0);
  
  React.useEffect( () => {
    setRows(rowsNumber);
  },[rowsNumber]);

  const handleBackButton = () => {
    onPageChange(page - 1);
  };

  const handleNextButton = () => {
    onPageChange(page + 1);
  };

  const handleRowsPerPageChange = (evt) => {
    onRowsPerPageChange(evt.target.value);
  };

  return (
      <Box sx={{ display:'flex', flexDirection: {xs:'column', sm: 'row'}, justifyContent:'space-between' }}>
        <Box>
          <Typography sx={{ display: {xs: 'block', sm: 'none' }, textAlign:'center', color: '#676565', pb:'1.2rem', fontSize: tableStyle.pagination.fontSize }}>{`Mostrando ${rows} resultados`}</Typography>
          <Select
            sx={{
              display:{xs:'none', sm:'inline-flex'},
              backgroundColor:'#FFFEFC', 
              fontSize: {xs:'0.875rem', md:'1rem'},
              '& .MuiSelect-select': {
                paddingRight: '0.75rem !important', 
              },
            }}
            IconComponent={() => <KeyboardArrowLeft sx={{transform: 'rotate(270deg)', mr: '0.75rem' }}/>}
            MenuProps={{
              MenuListProps:{
                fontSize: '5px'
              }
            }}
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            disabled={isLoading}
          >
            <MenuItem sx={tableStyle.SelectOptions} value={25}>25 resultados</MenuItem>
            <MenuItem sx={tableStyle.SelectOptions} value={50}>50 resultados</MenuItem>
            <MenuItem sx={tableStyle.SelectOptions} value={75}>75 resultados</MenuItem>
            <MenuItem sx={tableStyle.SelectOptions} value={100}>100 resultados</MenuItem>
          </Select>
        </Box>
        <Box sx={{ display:'flex', alignItems:'center', gap:{xs:'0rem', sm:'0.5rem', md:'0.75rem'}, justifyContent:{xs: 'space-between', sm:'unset'} }}>
          <IconButton
            sx={tableStyle.iconPagination}
            onClick={handleBackButton}
            disabled={page === 1 || isLoading}
          >
            <KeyboardArrowLeft/>
          </IconButton>
          <Typography sx={tableStyle.pagination}>Página</Typography>
          <Typography sx={tableStyle.pageNumber}>{page}</Typography>
          <Typography sx={tableStyle.pagination}>{`de ${totalPages}`}</Typography>
          <IconButton
            sx={tableStyle.iconPagination}
            onClick={handleNextButton}
            disabled={page === totalPages || isLoading}
          >
            <KeyboardArrowRight/>
          </IconButton>
        </Box>
      </Box>
  );
}
