import axios from '../utils/axios';

const SERVICE_URL = '/categories';

export const getCategoriesList = async () => {
    try {
        let response = await axios.get(`${SERVICE_URL}/client`);
        return response.data;
    } catch (error) {
        return {
            categories: [],
        };
    }
};
export const getCategorySorted = async (categoryName) => {
    try {
        let response = await axios.post(`${SERVICE_URL}/sorted`,{categoryName});
        return response.data;
    } catch (error) {
        return {
            groupedSubcategories: {},
        };
    }
};