import React from "react";
import { Box, Typography, Grid, FormControl, FormLabel, TextField, InputAdornment, Select, MenuItem, IconButton, colors, Hidden, Divider, Button, Stack } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { UserContext } from "../context/UserContext";

const iconStyles = {
    color: '#FC581F',
    fontSize: '2rem'
}

const FileDownloader = ({ file, open = null }) => {
    const { domain } = React.useContext(UserContext);

    const openPDF = () => {
        if(open) {
            open()
            return
        }
        const url = !!file.url ? `${domain}${file.url}` : URL.createObjectURL(file);
        window.open(url, '_blank');
    };

    const fileName = !!file.url ? file.fileName : file.name.split(`.${file.type.split('/')[1]}`)[0];
    
    return (
        <Box sx={{display:'flex', alignItems: 'center' }}>
            <IconButton sx={{padding: '0px', cursor: 'pointer' }} onClick={openPDF}>
                <FileDownloadOutlinedIcon sx={iconStyles}/>
            </IconButton>
            <Typography sx={{fontSize: {xs: '0.875rem'}, lineHeight: {xs: '1rem', lg:'1.125rem'}, pl:'0.5rem', flex: 1, overflow:'hidden', textOverflow:"ellipsis" }} >{fileName}</Typography>
        </Box>
    );
};

export default FileDownloader;
