import { Box, CircularProgress } from "@mui/material";
import { useContext, useEffect } from "react";
import { UserDispatchContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";

const circleSize = {
  xs: "10rem !important",
  md: "10rem !important",
  lg: "10rem !important",
  xl: "10rem !important",
};

const SuccessfulPayment = () => {
    const globalUserDispatchContext = useContext(UserDispatchContext);
    const navigate = useNavigate();

    useEffect(() => {
        const cartStorage = JSON.parse(localStorage.getItem('userCart')) || null;
      if(cartStorage){
        globalUserDispatchContext({
            type: 'DELETE_CART'
        });
        navigate('/');
      }
    
    }, [])
    
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      minHeight={"100vh"}
    >
      <CircularProgress
        sx={{ width: circleSize, height: circleSize, color: "#FC581F" }}
      />
    </Box>
  );
};

export default SuccessfulPayment;
