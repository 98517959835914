import axios from '../utils/axios';

const SERVICE_URL = '/orders';


export const ordersCreate = async (
    order
) => {
    try {
        let response = await axios.post(`${SERVICE_URL}/create`, order );
        return response.data;
    } catch (error) {
        return {result: false, error};
    }
}

export const getOrdersList = async (
    searchTerm,
    page,
    rowsPerPage,
    LastEvaluatedKey,
    userId
) => {
    try {
        let response = await axios.post(
            `${SERVICE_URL}/client`
            , 
            { 
                searchTerm,
                page,
                rowsPerPage,
                LastEvaluatedKey,
                userId
            }
        );
        return response.data;
    } catch (error) {
        return {
            items: [], 
            LastEvaluatedKey: {},
            totalPages: 1
        };
    }
}
