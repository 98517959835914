import axios from '../utils/axios';

const SERVICE_URL = '/users/client';

export const login = async (userForm) => {
    try {
        const userCart = JSON.parse(localStorage.getItem('userCart')) || null;
        let response = await axios.post(`${SERVICE_URL}/login`, {userForm, userCart});
        return response.data;
    } catch (error) {
        return error;
    }
}

export const userCreate = async (data) => {
    try {
        let response = await axios.post(`${SERVICE_URL}/create`, data);
        return response.data;
    } catch (error) {
        return error;
    }
}

export const getUser = async (id) => {
    try {
        let response = await axios.post(`${SERVICE_URL}/id?id=${id}`);
        return response.data;
    } catch (error) {
        return error;
    }
}

export const userEditAddress = async (item) => {
    try {
        let response = await axios.post(`${SERVICE_URL}/address`, item);
        return response.data;
    } catch (error) {
        return error;
    }
}

export const getPresignedUrlForCFDI = (userId) => {
    let route = `cfdi/${userId}`
    return axios.post(`${SERVICE_URL}/cfdi/pre-signed-url`, { route })
}
export const getPresignedUrlForTemporalCFDI = (cartId) => {
    let route = `cfdi/temporal/${cartId}`
    return axios.post(`${SERVICE_URL}/cfdi/pre-signed-url`, { route })
}

export const getPresignedUrlDownloadForCFDI = (userId) => {
    return axios.get(`${SERVICE_URL}/cfdi/pre-signed-url/${userId}`)
}

export const userUpdateCfdiStatus = (userId, cfdiUse) => {
    return axios.post(`${SERVICE_URL}/cfdi/update`, { id: userId, cfdiUse })
}
