import { 
    Box, Breadcrumbs, Button, Checkbox, Container,
    Divider, Drawer, FormControl, Grid, IconButton, List,
    ListItem, ListItemText, MenuItem, Select, Toolbar, Typography, Tooltip
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {useContext, useEffect, useState} from 'react'
import Footer from '../components/Footer'
import { NavLink, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getProductsByCategoryId } from '../services/productService';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { addItemToCart, formatNumberToCurrency, truncateString } from '../utils/utils';
import HalfGearIcon from '../components/Icons/HalfGearIcon';
import SearchBar from '../components/SearchBar';
import { UserContext, UserDispatchContext } from '../context/UserContext';
import { userCartAddItem } from '../services/userCartService';
import { useSnackbar } from 'notistack';
import '../index.css';
import { exchangeRatesByCode } from '../services/ExchangeRateService';

const fromPages = {
    products: 'Productos',
    measures: 'Magnitudes',
    applications: 'Aplicaciones',
    brand: 'Brand',
}

let fromPage
const ProductCategoryPage = () => {
    const globalUserDispatchContext = useContext(UserDispatchContext);
    const { domain } = useContext(UserContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [allProducts, setAllProducts] = useState([]);
    const { subCategoryId, type } = useParams()

    const [brands, setBrands] = useState([]);
    const [showBrandProducts, setShowBrandProducts] = useState(false);
    const [brand, setBrand] = useState(undefined);
    const [applications, setApplications] = useState([]);
    const [prices, setPrices] = useState([]);

    const [products, setProducts] = useState([]);
    const [subcategory, setSubcategory] = useState(null);

    const [brandFilter, setBrandFilter] = useState([]);
    const [priceFilter, setPriceFilter] = useState([]);
    const [applicationFilter, setApplicationFilter] = useState([]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [sortBy, setSortBy] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [dollarPrice, setDollarPrice] = useState(0);

    useEffect(() => {
        fromPage = type
        getSubcategoryData();
        getExchangeRateByCode();
    }, []);

    useEffect(() => {
        if(allProducts.length > 0){
            const productsFiltered = filterProducts();
            sortProducts(productsFiltered);
            countFilteredItems(productsFiltered);
        }
    }, [brandFilter, priceFilter, applicationFilter]);

    useEffect(() => {
        if(allProducts.length > 0){
            sortProducts(products);
        }
    }, [sortBy]);

    const getSubcategoryData = async () => {
        const categoryId = subCategoryId
        let result 
        if(fromPage === 'brand') {
            result = await getProductsByCategoryId(null, fromPage, decodeURI(categoryId));
            setBrand(decodeURI(categoryId))
            setShowBrandProducts(true)
        }
        else {
            result = await getProductsByCategoryId(categoryId, fromPage);
            if(!result.subcategory || !Object.keys(result.subcategory).length){
                navigate(`${pathname}/not-found`);
                return;
            }
        }
        setAllProducts(!!result.products ? result.products : []);
        setBrands(!!result.allBrands ? result.allBrands : []);
        setPrices(!!result.allPrices ? result.allPrices : []);
        setApplications(!!result.allApplications ? result.allApplications : []);
        if(fromPage === "applications") {
            setApplicationFilter([ result.subcategory.subCategoryNameNormalized ])
        }
        setSubcategory(!!result.subcategory ? result.subcategory : null);
        sortProducts(!!result.products ? result.products : []);
    };

    const getExchangeRateByCode = async () => {
        setIsLoading(true);
        let { message, result, exchangeRate } = await exchangeRatesByCode("USD");
        if (result) {
          setDollarPrice(exchangeRate.exchangeRate);
          setIsLoading(false);
        } else {
            setDollarPrice(0);
            enqueueSnackbar(message, {
                variant: "error",
            });
        }
    };

    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleChangeOrderBy = (event) => {
        setSortBy(event.target.value);
    };

    const handleBrandCheckboxChange = (item) => {
        if (brandFilter.includes(item)) {
            setBrandFilter(brandFilter.filter((value) => value !== item));
        } else {
            setBrandFilter([...brandFilter, item]);
        }
    };
    const handlePriceCheckboxChange = (item) => {
        if (priceFilter.includes(item)) {
            setPriceFilter(priceFilter.filter((value) => value !== item));
        } else {
            setPriceFilter([...priceFilter, item]);
        }
    };
    const handleApplicationCheckboxChange = (item) => {
        if (applicationFilter.includes(item)) {
            setApplicationFilter(applicationFilter.filter((value) => value !== item));
        } else {
            setApplicationFilter([...applicationFilter, item]);
        }
    };

    const filterProducts = () => {
        return allProducts.filter(product => {
          if (brandFilter.length > 0) {
            const productBrands = product.brandsSubcategories;
            if (!productBrands.some(brand => brandFilter.includes(brand))) {
              return false;
            }
          }
      
          if (priceFilter.length > 0) {
            if (!priceFilter.includes(product.priceRange)) {
              return false;
            }
          }

          if (applicationFilter.length > 0) {
            const productApplications = product.applicationsSubcategories;
            if (!productApplications.some(application => applicationFilter.includes(application))) {
              return false;
            }
          }
      
          return true;
        });
    };

    const sortProducts = (productsFiltered) => {
        const sortedProducts = [...productsFiltered];
        switch (sortBy) {
          case 1:
            sortedProducts.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
            break;
          case 2:
            sortedProducts.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
            break;
          case 3:
            sortedProducts.sort((a, b) => a.productName.localeCompare(b.productName));
            break;
          case 4:
            sortedProducts.sort((a, b) => b.productName.localeCompare(a.productName));
            break;
          default:
            sortedProducts.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
            break;
        }
        setProducts(sortedProducts);
    };

    const countFilteredItems = (filteredProducts) => {

        const brandCounts = {};
        const applicationCounts = {};
        const priceCounts = {};

        Object.keys(brands).forEach(key => brandCounts[key]= 0);
        Object.keys(applications).forEach(key => applicationCounts[key]= 0);
        Object.keys(prices).forEach(key => priceCounts[key]= 0);
      
        filteredProducts.forEach((product) => {
            product.brandsSubcategories.forEach((brand) => {
                if (brandCounts[brand]) {
                    brandCounts[brand]++;
                } else {
                    brandCounts[brand] = 1;
                }
            });
      
            product.applicationsSubcategories.forEach((application) => {
                if (applicationCounts[application]) {
                    applicationCounts[application]++;
                } else {
                    applicationCounts[application] = 1;
                }
            });
      
            const priceLabel = product.priceRange;
            if (priceCounts[priceLabel]) {
                priceCounts[priceLabel]++;
            } else {
                priceCounts[priceLabel] = 1;
            }
        });
        setBrands(brandCounts);
        setPrices(priceCounts);
        setApplications(applicationCounts);
    };

    const breadcrumbsItemStyle = {
        fontSize:{ xs: 10, lg:13},
    };

    const filterGroupComponent = (filterName, filterList, filterGroup, handleCheckboxOnChange) =>{
        return (
            <Box sx={{pt:{xs:'10px', lg:'30px'} }}>
                <Typography sx={{color: '#A1A1A1', fontSize: {xs:13, lg:12}, fontWeight:'bold' }}>
                    {filterName}
                </Typography>
                <List>
                    {Object.keys(filterList).map((key, index) => (
                    <ListItem key={index} sx={{ p:0 }}>
                        <Checkbox
                            size='small'
                            sx={{p:0}}
                            checked={filterGroup.includes(key)}
                            onChange={() => handleCheckboxOnChange(key)}
                        />
                        <ListItemText 
                            primaryTypographyProps={{
                                sx: { fontSize: 14, pl:'5px' }
                            }}
                            primary={key} 
                        />
                        <Typography sx={{fontSize: 14 }}>({filterList[key]})</Typography>
                    </ListItem>
                    ))}
                </List>
            </Box>
        )
    }

    const filterComponent = () => {
        return (
            <Box>
                <Typography sx={{fontWeight:'bold', color: 'rgba(48, 48, 48, 0.97)', pb: {lg: '5px'}, fontSize:{xs:15, lg:16} }}>Ordenar por:</Typography>
                <Divider sx={{borderColor: '#FFEBE6', borderWidth: '1px', display:{xs:'none', lg:'block'} }}/>
                <FormControl sx={{ width:'100%', pt: {xs:'20px',lg:'30px'} }} size="small">
                    <Select
                        placeholder='selecciona el filtro'
                        value={sortBy}
                        onChange={handleChangeOrderBy}
                        sx={{
                            "&.MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                    borderColor: "#303030"
                                }
                            }
                        }}
                        IconComponent={() => <NavigateNextIcon sx={{transform: 'rotate(90deg)', color: '#FC581F', fontSize: {xs:'1.7rem', lg:'2rem'} }}/>}
                    >
                        <MenuItem value={0}>Popular</MenuItem>
                        <MenuItem value={1}>Menor a mayor precio</MenuItem>
                        <MenuItem value={2}>Mayor a menor precio</MenuItem>
                        <MenuItem value={3}>Nombre: A - Z</MenuItem>
                        <MenuItem value={4}>Nombre: Z - A</MenuItem>
                    </Select>
                </FormControl>
                <Typography sx={{fontWeight:'bold', color: 'rgba(48, 48, 48, 0.97)', pb: {lg: '5px'}, pt: {xs:'20px',lg:'30px'}, fontSize:{xs:15, lg:16} }}>Filtros:</Typography>
                <Divider sx={{borderColor: '#FFEBE6', borderWidth: '1px', display:{xs:'none', lg:'block'} }}/>
                {filterGroupComponent("MARCA", brands, brandFilter, handleBrandCheckboxChange)}
                {filterGroupComponent("PRECIO", prices, priceFilter, handlePriceCheckboxChange)}
                {filterGroupComponent("APLICACIONES", applications, applicationFilter, handleApplicationCheckboxChange)}
            </Box>
        )
    }

    const addToCart = async (product) => {
        const newCart = addItemToCart(product);
        try {
            const userId = localStorage.getItem('UID');
            if(userId && !isLoading){
                setIsLoading(true);
                const result = await userCartAddItem(newCart);
                setIsLoading(false);
                if(result.result){
                    if(!newCart._id){
                        newCart._id = result.userCartId;
                    }
                    enqueueSnackbar(result.message, {
                        variant: 'success'
                    });
                }else{
                    enqueueSnackbar(result.message, {
                        variant: 'error'
                    });
                    return;
                }
            }else{
                enqueueSnackbar('articulo agregado al carrito.', {
                    variant: 'success'
                });
            }
            globalUserDispatchContext({
                type: 'ADD',
                userCart: newCart
            });
        } catch (error) {
            console.log(error);
            enqueueSnackbar('ocurrio un problema al agregar al carrito', {
                variant: 'error'
            });
        }
    };

    const setMaxText = (text, max) => {
        if(!text)
          return ''
        if(text.length > max)
          text = text.substr(0, max) + '...'
        return text
      }

    return (
        <Box minHeight={'100vh'} display={'flex'} flexDirection={'column'} sx={{backgroundColor:'#F8F8F8'}}>
            <Toolbar disableGutters sx={{ pb: {xs: 2, lg: 3}, display: {xs: 'none', lg:'block'} }}/>
            <Container maxWidth="xl" sx={{ display:'flex', flexGrow: 1, width:'100%', px:{xs:0, lg:'50px'}, pb:{xs:'30px'}, pt:{lg:'30px'} }}>
                <Box sx={{maxWidth:'1512px', width:'100%', margin:'0 auto'}}>
                    <Box sx={{display:'flex', height:'100%'}}>
                        <Box sx={{
                            display:{xs:'none', lg:'block'},
                            width:'23.5%', 
                            borderRight: '2px solid #D9D9D9',
                            pr: {lg: '1.875rem'}
                        }}>
                           {filterComponent()}
                        </Box>
                        <Drawer
                            anchor={'left'}
                            open={isMenuOpen}
                            onClose={handleToggleMenu}
                            PaperProps={{ sx: { width: {xs:'53%', sm:'35%', md:'20%'}, px:'5%' } }}
                            sx={{display:{lg: 'none'}}}
                        >
                            <Box sx={{width:'100%', pt:'10px'}}>
                                <Box sx={{display: 'flex', justifyContent: 'end'}}>
                                    <IconButton
                                        sx={{p:0, mr:'-5px'}}
                                    onClick={handleToggleMenu}
                                    >
                                    <CloseOutlinedIcon sx={{ color: '#FC581F', fontSize:'32px' }} />
                                    </IconButton>
                                </Box>
                                {filterComponent()}
                            </Box>   
                        </Drawer>
                        <Box sx={{ 
                            width: '100%',
                            pl:{lg: '25px'}
                        }}>
                            <Box
                                sx={{
                                    display:'flex', 
                                    flexDirection:'column', 
                                    px:{lg: '25px'}, 
                                    position:{xs:'fixed', lg: 'inherit'},
                                    zIndex: {xs:3 ,lg:0},
                                    width:'100%',
                                    mb: { xs: '10px' }
                                }}
                            >
                                <Box sx={{
                                    display: {xs:'flex', lg:'none'}, 
                                    justifyContent: 'space-between', 
                                    backgroundColor:'#F8F8F8',
                                    pt:{xs:9, lg:0},
                                    px: {xs:'25px', sm:'30px', md:'50px', lg: 0}
                                }}>
                                    <Box sx={{width:'100%', pr:'0.62rem'}}>
                                        <SearchBar/>
                                    </Box>
                                    <IconButton
                                        sx={{p:0}}
                                    onClick={handleToggleMenu}
                                    >
                                        <FilterAltOutlinedIcon sx={{ color: 'rgba(48, 48, 48, 0.97)', fontSize:'30px' }} />
                                    </IconButton>
                                </Box>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon sx={{ color: '#FC581F',fontSize:{xs:12, lg:15}}} />}
                                    sx={{
                                        '& .MuiBreadcrumbs-separator': {mx:0},
                                        pb:{xs:'10px'},
                                        pt:{xs:1, lg:0},
                                        backgroundColor:'#F8F8F8',
                                        px: {xs:'25px', sm:'30px', md:'50px', lg: 0}
                                    }}
                                >
                                    <NavLink
                                        onClick={() => {
                                            if(showBrandProducts) {
                                                return
                                            }
                                            navigate(fromPage  ? `/${fromPage.toLowerCase()}` : `/${pathname.split('/')[1]}`)
                                        }}
                                        style={{textDecoration:'none'}}
                                    >
                                        <Typography component={'span'} key="1" underline='hover' sx={{ ...breadcrumbsItemStyle, color: '#FC581F', display: 'flex', '&:hover': { textDecoration:'underline'} }}>
                                            {fromPages[fromPage]}
                                        </Typography>
                                    </NavLink>
                                    <Typography component={'span'} key="2" sx={{ ...breadcrumbsItemStyle, color: 'rgba(48, 48, 48, 0.97)'}}>
                                        { showBrandProducts ? brand : !!subcategory ? subcategory.subCategoryName : ''}
                                    </Typography>,
                                </Breadcrumbs>
                                <Box sx={{display:'flex', backgroundColor:'#F8F8F8', px: {xs:'25px', sm:'30px', md:'50px', lg: 0}}}>
                                    <NavLink onClick={() => navigate(showBrandProducts ? -1 : fromPage  ? `/${fromPage.toLowerCase()}` : `/${pathname.split('/')[1]}`)}>
                                            <NavigateNextIcon sx={{transform: 'rotate(180deg)', ml:{xs:'-8px'}, fontSize:{sm:30, lg:36}, color: '#FC581F'}}/>
                                    </NavLink>
                                    <Typography sx={{
                                        color: '#000',
                                        fontSize: {xs: 16, sm:20, lg: 24},
                                        fontWeight:"bold",
                                        pb:{xs:1, sm:0, lg:0}
                                    }}>
                                        { !!subcategory ? subcategory.subCategoryName : ''}
                                    </Typography>
                                </Box>
                                {/* <Box sx={{width: '100%', height:{xs:'39px', sm:'35px'}, px: {xs:'25px', sm:'30px', md:'50px', lg: 0}, background: 'linear-gradient(180deg, #F8F8F8 0%, rgba(248, 248, 248, 0.00) 100%)' }}/> */}
                            </Box>
                            {subcategory?.description && (
                                <Tooltip title={subcategory?.description.length >= 700 ? subcategory.description : ''} arrow>
                                    <Box sx={{px: {xs:'25px', sm:'30px', md:'9.8%', lg: 0}, pt:{ xs:28, lg:0}}}>
                                        <p style={{
                                                fontSize: {xs: '0.75rem'},
                                                // fontWeight: {xs: '300', md: '400'},
                                                lineHeight: '1.5',
                                                // maxHeight: {xs: '6.25rem', md: '7rem', lg: '6.4rem'},
                                                // height:{lg:'6.4rem'},
                                                color: '#232323',
                                                padding: '16px',
                                                overflow:'auto',
                                                textAlign: 'justify',
                                            }}
                                        >
                                            {!!subcategory && setMaxText(subcategory.description,  680)}
                                        </p>
                                    </Box>
                                </Tooltip>
                            )}
                            <Box sx={{px: {xs:'25px', sm:'30px', md:'9.8%', lg: 0}, pt:{ xs: subcategory?.description ? 2 : 29, lg:0}}}>
                                <Grid container spacing={{xs:'30px', lg:'25px'}} >
                                    {products.map( product => (
                                        <Grid 
                                            item 
                                            xs={12}
                                            sm={6}
                                            lg={4}
                                            key={product._id}
                                        >
                                            <NavLink
                                                style={{ textDecoration: 'none', color: 'black', fontSize:'13px', fontWeight: 'bold' }}
                                                to={ `/products/details/${product._id}`}
                                            >
                                                <Box
                                                    sx={{
                                                        display:'flex',
                                                        position: 'relative',
                                                        borderRadius: '8px',
                                                        padding:{xs:'20px 0px'},
                                                        backgroundColor:'white',
                                                        boxShadow: '2px 4px 10px 0px rgba(0, 0, 0, 0.10)',
                                                        height:{xs: 'calc(100% - 40px)'},
                                                        minHeight:'130px'
                                                    }}
                                                    className="card-container"
                                                >
                                                    <Box className="image" sx={{width:'30%', display:'flex', justifyContent:'center', alignItems: 'center', zIndex:2 }}>
                                                        { !!product.images.length &&
                                                            <img 
                                                                width={'100%'}
                                                                height={'max-content'}
                                                                alt=''
                                                                loading="lazy"
                                                                src={`${domain}${product.images[0].url}`}
                                                                onError={(e) => {
                                                                    e.target.style.display = 'none';
                                                                    e.target.nextSibling.style.display = 'flex'
                                                                }}
                                                            />
                                                        }
                                                        <Box sx={{display: !!product.images.length ? 'none' : 'flex' , alignItems:'center', justifyContent:'center', height:'100%'}}>
                                                            <Typography sx={{fontSize: {xs:13, md:16}, textAlign: 'center' }}>Sin imagen</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{width:'70%', mx:{xs: '10px'}, pr:{ xs: '20px'}, zIndex:2 }}>
                                                        <Box sx={{}} >
                                                            <Typography 
                                                                sx={{
                                                                    fontSize:{ xs: 14 },
                                                                    fontWeight: 'bold'
                                                                }}
                                                                className='card'
                                                            >
                                                                {product.productName.toUpperCase()}
                                                            </Typography>
                                                            <Divider sx={{height:'1px', borderColor: '#FC581F'}}/>
                                                            <Typography sx={{py:{xs:'10px'}, fontSize:{xs:13} }}>
                                                                {truncateString(product.description,50)}
                                                            </Typography>
                                                            <Typography 
                                                                sx={{
                                                                    fontSize:{xs:15},
                                                                    fontWeight: 'bold'
                                                                }}
                                                            >
                                                                {`${formatNumberToCurrency(product.price * dollarPrice)} MXN`}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{position:'absolute', right:0, top: '20px', height: 'calc(100% - 40px)' }}>
                                                        <HalfGearIcon/>
                                                    </Box>
                                                </Box>
                                            </NavLink>
                                        </Grid>
                                    ))}
                                </Grid>
                                {!products.length && <Typography sx={{fontWeight:'bold', pt:{xs:4}}}>Sin productos.</Typography>}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <Footer/>
        </Box>
    )
}

export default ProductCategoryPage