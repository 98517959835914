import { createContext, useReducer } from "react";
import { getCount } from "../utils/utils";

export const UserContext = createContext();
export const UserDispatchContext = createContext();

export const UserProvider = ({ children }) => {
    const [userCart, userActions] = useReducer(
        userReducer,
        initialState
    );
    
    return (
        <UserContext.Provider value={userCart}>
            <UserDispatchContext.Provider value={userActions}>
                {children}
            </UserDispatchContext.Provider>
        </UserContext.Provider>
    );
}

function userReducer(state, action) {
  try {
    switch (action.type) {
      case 'INIT_CART': {
        const newCart = JSON.parse(localStorage.getItem('userCart')) || null;
        return {
          ...state,
          userCart: newCart,
          itemsCount: getCount(newCart)
        };
      }
      case 'ADD': {
        localStorage.setItem('userCart', JSON.stringify(action.userCart));
        return {
          ...state,
          userCart: action.userCart,
          itemsCount: getCount(action.userCart)
        };
      }
      case 'DELETE_CART': {
        localStorage.removeItem('userCart');
        return { initialState };
      }
      
      default: {
        throw Error('Unknown action: ' + action.type);
      }
    } 
  } catch (error) {
    console.log('error al guardar');
  }
}
  
const initialState = {
  userCart: null, 
  itemsCount: 0,
  domain: window.location.protocol + "//" + window.location.host + "/assets/"
};