import { Button } from '@mui/material';

const ButtonStyled = ({text, primary=true, handleClick, isDisabled = false, customStyle = {}}) => {
    return (
      <Button
        disabled={isDisabled}
        variant='contained'
        onClick={handleClick}
        sx={{ 
          width:{xs:'100%', lg: 'auto'},
          fontSize: '0.875rem',
          fontWeight:'bold',
          backgroundColor: primary ? '#FC581F' : '#FFF',
          color: primary ? '#FFF' : '#303030',
          textTransform:'none',
          minWidth: {xs: 0,lg: '64px'},
          padding: {xs: '0.75rem 1rem', lg:' 1rem 2.0625rem'},
          borderRadius:'0.25rem',
          boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)',
          '&:hover': { 
            backgroundColor: primary ? '#FC581F' : '#FFF'
          },
          ...customStyle
        }}
      >
        {text}
      </Button>
    )
  }
  
  export default ButtonStyled