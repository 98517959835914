import React, {useState, useEffect} from 'react'
import { Box, Typography, Link, Divider, List, ListItem } from '@mui/material';
import { getCategorySorted } from '../services/categoriesService';
import Footer from '../components/Footer';
import { NavLink } from 'react-router-dom';
import SearchBar from '../components/SearchBar';

const CATEGORY_NAME = 'Productos';

export default function ProductsPage() {
    
    const SCROLL_OFFSET = -365;
    const Alphabet = [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'Ñ', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z' ];

    const [groupedSubcategories, setGroupedSubcategories] = useState({});
    const [availableLetters, setAvailableLetters] = useState([]);
    
    useEffect(() => {
        getCategoryData();
    }, []);

    const getCategoryData = async () => {
        const result = await getCategorySorted(CATEGORY_NAME);
        setGroupedSubcategories(result.groupedSubcategories);
        setAvailableLetters(Object.keys(result.groupedSubcategories));
    };

    return (
        <Box height={'100vh'} display={'flex'} flexDirection={'column'} >
            <Box sx={{
                position: 'fixed',
                zIndex:2,
                width:'100%'
            }}>
                <Box sx={{display:'flex', width:'100%'}}>
                    <Box sx={{px:{xs:'6.42%'}, pt:{xs:'4.31rem', sm: '4.625rem', md: '4.625rem', lg:14}, width:'100%', backgroundColor: '#F8F8F8' }}>
                        <Box sx={{pb:{xs:'2.5rem'}, display:{xs:'block', lg:'none'}}}>
                            <SearchBar/>
                        </Box>
                        <Typography sx={{
                            color: 'rgba(48, 48, 48, 0.97)',
                            fontSize: {xs:20, sm:20, md:23, lg:27, xl:30},
                            pb:{xs:'1.25rem', lg:5 }
                        }}>
                            <b>Productos </b>
                        </Typography>
                        <Box sx={{pb: {xs: '1.31rem', md:'1.88rem'}, display:{lg:'flex'}, alignItems:{lg:'center'}, width:'100%' }}>
                            <Box sx={{width:{lg:'auto'}}} >
                                <Typography sx={{
                                color: 'rgba(48, 48, 48, 0.97)',
                                fontSize: {xs:13, sm:13, md:15, lg:18 },
                                pb:{xs:'1.25rem', lg:0 },
                                fontWeight: 'bold'
                            }}>
                                Busca productos A-Z
                                </Typography>
                            </Box>
                            <Box sx={{width:{lg: '75%'}, pl:{lg:6}}}>
                                <Box sx={{ display: {xs:'flex', lg:'block'}, width:{lg:'100%'}, flexWrap: {xs:'wrap'}, justifyContent:{xs:'space-between', sm:'center', md:'flex-start', px:{xs:'5.89%', sm: '0%'} }}}>
                                    {Alphabet.map(letter => {
                                        const letterExist = availableLetters.includes(letter);
                                        const styleLink = {
                                            fontSize:{ xs: 14, lg: 16 },
                                            display: {xs:'flex', lg:'inline-block'},
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: {xs: '30px', md:'38px', lg:'auto'},
                                            width: {xs: '22px', sm:'24px', md:'3.5%', lg:'24px', xl:'24px'},
                                            color: 'rgba(48, 48, 48, 0.97)',
                                            opacity: letterExist ?  1 : 0.25,
                                            ...(letterExist && {
                                                '&:hover': {
                                                    color: '#FC581F'
                                                },
                                            })
                                        };
                                        return (
                                            <Link
                                                key={letter}
                                                sx={
                                                    styleLink
                                                }
                                                underline="none"
                                                href={letterExist ? `#${letter}` : undefined}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    const target = document.getElementById(letter);
                                                    if (target) {
                                                        const offsetTop = target.getBoundingClientRect().top + window.scrollY;
                                                        window.scrollTo({
                                                            top: offsetTop + SCROLL_OFFSET,
                                                            behavior: "smooth"
                                                        });
                                                    }
                                                }}
                                            >
                                                <b>{letter}</b>
                                            </Link>
                                        )
                                    })}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box backgroundColor={'#F8F8F8'}>
                    <Divider sx={{borderWidth: '1px', borderColor: '#D9D9D9'}} variant='fullWidth'/>
                </Box>
                <Box sx={{width:'100%', height:{xs: '39px', lg:'60px'}, background: 'linear-gradient(180deg, #F8F8F8 0%, rgba(248, 248, 248, 0.00) 100%)'}}/>
            </Box>

            <Box sx={{
                pt:{ xs:'23rem', sm:'23rem', lg:'19rem'},
                pb:{xs:'30px'},
                px:{xs:'14%', lg:'6.42%'},
                backgroundColor: '#F8F8F8',
                flexGrow: 1
            }}
            >
                <List position={'relative'} sx={{py:0}} >
                    {Object.entries(groupedSubcategories).map(([letter, subcategories]) => (
                        <React.Fragment key={letter}>
                            <ListItem key={letter} id={`${letter}`} sx={{ px:0 , py:0 }} >
                                <Typography sx={{
                                    position:'absolute', left:0, top: {xs:'-6px', md:'-15px', lg:'-12px'},
                                    fontSize: { xs:30, md:'50px'},
                                }}>
                                    <b>{letter}</b>
                                 </Typography>
                                <List sx={{py:0, paddingLeft: {xs:'55px'}}}>
                                    {subcategories.map(({_id, subCategoryName, subCategoryNameNormalized, subByCategoryId}) => ( 
                                        <NavLink
                                            key={_id}
                                            style={{ textDecoration: 'none' }}
                                            to={`category/${_id}?from=products`}
                                            className={({ isActive, isPending }) =>
                                            isActive
                                                ? "active"
                                                : isPending
                                                ? "pending"
                                                : ""
                                            }
                                        >
                                            <Typography sx={{
                                                pb:{xs: 1.25, sm:1.5, md:3 }, 
                                                fontSize:{xs:13, md:16, lg:24},
                                                color: 'black',
                                                '&:hover': {
                                                    color: '#FC581F'
                                                },
                                            }}>
                                                {subCategoryName}
                                            </Typography> 
                                        </NavLink>
                                    ))}
                                </List>
                            </ListItem>
                            <Divider key={`${letter}divider`} sx={{my: { xs:3.75 } ,borderWidth: '1px', borderColor: '#D9D9D9'}} variant='fullWidth'/>
                        </React.Fragment>
                    ))}
                </List>
            </Box>
            <Footer/>
        </Box>
)
}
