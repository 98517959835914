function WhiteCartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="26"
      fill="none"
      viewBox="0 0 38 26"
    >
      <path
        fill="#fff"
        d="M10.543 4.689H34.29l-2.351 10.58H10.143L6.068 1.683A2.072 2.072 0 004.084.208H0v2.975h3.412l4.093 13.644a1.987 1.987 0 001.903 1.416h23.325c.932 0 1.738-.647 1.94-1.556L38 1.713H11.987c-.798 0-1.444.647-1.444 1.444V4.69zM12.048 25.792a3.012 3.012 0 100-6.024 3.012 3.012 0 000 6.024zM30.122 25.792a3.012 3.012 0 100-6.024 3.012 3.012 0 000 6.024z"
      ></path>
      <path
        fill="#fff"
        d="M21.085 5.895c-4.328 0-7.85 3.52-7.85 7.849 0 .319.244.319.614.319h1.588v-.638H13.88a7.176 7.176 0 011.625-4.245l1.158 1.036.425-.475-1.158-1.035a7.19 7.19 0 014.836-2.166v1.556h.638V6.54a7.19 7.19 0 014.836 2.166L25.082 9.74l.425.475 1.158-1.036a7.175 7.175 0 011.625 4.245h-1.557v.637h2.201v-.318c0-4.328-3.521-7.85-7.85-7.85z"
      ></path>
      <path
        fill="#fff"
        d="M20.264 11.587l-.022.016c-.042.03-.082.063-.122.098a1.377 1.377 0 101.995 1.88c.868-1.004.87-2.805.87-2.805-1.417 0-2.316.515-2.702.796l-.02.015z"
      ></path>
    </svg>
  );
}

export default WhiteCartIcon;
