import React, { useContext, useEffect, useRef, useState } from 'react'
import { Switch, Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, Icon, IconButton, InputAdornment, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField, Typography, styled, Tooltip } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ItemCart from '../components/ItemCart';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext, UserDispatchContext } from '../context/UserContext';
import { userCartUpdate, userCartCreatePreference, getQuoteShipping, validateProductStockQuoted, updateInvoicePreference, getCartById, getStockByItemsCart } from '../services/userCartService';
import { useSnackbar } from 'notistack';
import Footer from '../components/Footer';
import { currencyFormat, getBasePrice, sumExtraPrice, truncateDecimal } from '../utils/utils';
import MercadoPagoButton from '../components/MercadoPagoButton';
import { getPresignedUrlForTemporalCFDI, getUser } from '../services/userService';
import { exchangeRatesByCode } from '../services/ExchangeRateService';
import { MexicanStates } from '../utils/enums';
import BoxIcon from '../components/Icons/BoxIcon';
import FileIcon from '../components/Icons/FileIcon';
import FileDownloader from '../components/FileDownloader';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';

const formLabelStyle = {color: '#A1A1A1', fontWeight:'bold', fontSize:{xs: '1rem', display: 'flex'} };
const inputStyle = {sx: { fontSize:{xs: '1rem', md: '1rem'}, background:'#FFFEFC', borderRadius: '0.25rem' }};


const requiredLabelStyle = {color: '#FC581F', fontWeight:'bold'};
const optionalLabelStyle = {color: '#A1A1A1', fontSize: '0.875rem', pl:'0.1rem'};

const defaultErrorValues = {
    emailError: false,
    fullNameError: false,
    phoneError: false,
    addressError: false,
    colonyError: false,
    postalCodeError: false,
    regionError: false,
    cityError: false,
};

const requiredValues = ['email', 'fullName','phone','address','colony','postalCode','region','city' ];
const buyAsGuestFormBase = {
    email: '',
    fullName: '',
    phone: '',
    address: '',
    colony: '',
    postalCode: '',
    region: '',
    city: '',
    references: '',
    cfdiUse: '',
}

const errorValues = {
    emailError: 'correo electrónico',
    fullNameError: 'nombre',
    phoneError: 'teléfono',
    addressError: 'calle y número',
    colonyError: 'colonia',
    postalCodeError: 'código postal',
    regionError: 'estado',
    cityError: 'ciudad',
};

let fromPage
const CartPage = () => {
    const globalUserDispatchContext = useContext(UserDispatchContext);
    const globalUserContext = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [userCart, setUserCart] = useState({items:[]});
    const [user, setUser] = useState({});
    const [subtotal, setSubtotal] = useState(0);
    const [userAddress, setUserAddress] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [stepper, setStepper] = useState(0);
    const [isQuotingShipping, setIsQuotingShipping] = useState(false);
    const [preferenceId, setPreferenceId] = useState(null);
    const [shippingCost, setShippingCost] = useState(0);
    const [shippingQuotes, setShippingQuotes] = useState([]);
    const [shippingType, setShippingType] = useState(0);
    const userId = localStorage.getItem('UID') || null;
    const [dollarPrice, setDollarPrice] = useState(0);
    const [quotedProducts, setQuotedProducts] = useState([]);
    const [observeCfdi, setObserveCfdi] = useState(false);
    const [itemsCartStock, setItemsCartStock] = useState([]);
    
    const [showBuyAsGuestForm, setShowBuyAsGuestForm] = useState(false);
    const [buyingAsGuest, setBuyingAsGuest] = useState(false);
    const [buyingFormValidated, setBuyingFormValidated] = useState(false);
    const [buyAsGuestFormErrors, setBuyAsGuestFormErrors] = useState(defaultErrorValues)
    const [buyAsGuestForm, setBuyAsGuestForm] = useState(buyAsGuestFormBase);
    const [searchParams, setSearchParams] = useSearchParams();
    const [invoiceRequested, setInvoiceRequested] = useState(false);
    const [file, setFile] = useState(null)
    const [fileData, setFileData] = useState(null)
    const inputRef = useRef(null);

    const handleChange = (event) => {
        setInvoiceRequested(event.target.checked);
    };

    useEffect(() => {
        setShippingCost(0);
        setShippingQuotes([]);
        setQuotedProducts([]);
        const cartStorage = JSON.parse(localStorage.getItem('userCart')) || null;
        if(!cartStorage) {
            navigate('/');
            return;
        }
        setUserCart(cartStorage);
        loadData(cartStorage)
        getUserAddress();
    }, [globalUserContext]);

    
    const style = {
        header: {
            color: '#7C7B7B',
            fontSize: '0.875rem',
            width: 'calc( (100% - 1.5rem) / 3 ) '
        },
        rows: { display:'flex', gap: '0.625rem', justifyContent:'space-between', alignItems:'center', flexWrap: 'wrap' },
        text1: {color: '#303030', fontSize: {xs: '0.875rem', md: '1rem'}, lineHeight: '1.125rem'},
        text2: {color: '#303030', fontSize: {xs: '0.875rem', md: '1rem'}, lineHeight: '1.125rem', fontWeight :'bold'},
        text3: {color: '#929191', lineHeight: '0.875rem', fontSize: '0.75rem'},
        radioIcon: {
            '& .MuiSvgIcon-root': {
              fontSize: '1.125rem',
            },
            color: isQuotingShipping ? 'rgba(0, 0, 0, 0.38)': '#303030',
            '&.Mui-checked': {
                color: isQuotingShipping || !shippingQuotes.length ? 'rgba(0, 0, 0, 0.38)': '#FC581F',
            },
        },
        radioLabel: {'& .MuiFormControlLabel-label': {fontSize: {xs: '0.875rem', md: '1rem'}} }
    }

    const loadData = async (cartStorage) => {
        if(cartStorage._id) {
            getInvoiceRequestedStatus(cartStorage._id)
        }
        try {
            const dollarValue = await getExchangeRateByCode();
            const auxSubtotal = getSubTotal(cartStorage, dollarValue);
            setSubtotal(auxSubtotal);
        } catch (error) {
            console.log(error);
        }

        const itemsStock = await getStockByItemsCart(cartStorage, !cartStorage._id);
        if(itemsStock.result){
            setItemsCartStock(itemsStock.itemsCartStock);
        }
    }

    const getExchangeRateByCode = async () => {
        setIsLoading(true);
        let { message, result, exchangeRate } = await exchangeRatesByCode("USD");
        if (result) {
          setDollarPrice(exchangeRate.exchangeRate);
          setIsLoading(false);
          return exchangeRate.exchangeRate
        } else {
            setDollarPrice(0);
            enqueueSnackbar(message, {
                variant: "error",
            });
        }
        return 0;
    };

    const getUserAddress = async () => {
        const uId = localStorage.getItem('UID');
        if(!uId) return;
        const result = await getUser(uId);
        if(result.user && !!Object.keys(result.user).length){
            setUser(result.user)
            const address = `${result.user.address} ${result.user.colony} ${result.user.postalCode}`
            setUserAddress(address);
        }
    }

    const quoteShipping = async (cartId) => {
        const uId = localStorage.getItem('UID');
        if(!uId && !buyingAsGuest) return;
    
        setIsLoading(true);
        setIsQuotingShipping(true);
        const result = await getQuoteShipping(cartId, { ...userCart, ...buyAsGuestForm, invoiceRequested }, buyingAsGuest);
        setIsQuotingShipping(false);

        if(buyingAsGuest && !!fileData) {
            try {
                await uploadCfdi(result.userCartId)
            } catch (error) {
                console.error(error);
                enqueueSnackbar('Error subiendo constancia de situación fiscal.', {
                    variant: 'error'
                });
                setInvoiceRequested(false)
                setFileData(null)
            }
        }

        if(!!result.result){
            setQuotedProducts(result.quotedProducts);
            if(!!result.quote.length){
                const auxQuotes = result.quote.map(quote => ({
                    price: parseFloat(quote.price),
                    ...quote
                }));
                setShippingQuotes(auxQuotes);
                setShippingCost(auxQuotes[0].price);
                if(buyingAsGuest) {
                    setUserCart(prev => ({ ...prev, _id: result.userCartId }));
                }
            }else{
                setShippingCost(null);
            }
            setShippingType(0);
        }
        else{
            setShippingCost(0);
            setStepper(0);
            enqueueSnackbar(result.message, {
                variant: 'error'
            });
        }

        setIsLoading(false);
    }

    const uploadCfdi = async (cartId) => {
        let presignedUrl
        try {
            let res = await getPresignedUrlForTemporalCFDI(cartId)
            presignedUrl = res.data
        } catch (error) {
            console.error(error)
            enqueueSnackbar('Error subiendo constancia de situación fiscal.', {
                variant: 'error'
            });
            throw new Error()
        }
        try {
            const base64Response = await fetch(fileData);
            const blob = await base64Response.blob();
            await axios.put(presignedUrl, blob, {
                headers: {
                    'Content-Type': 'application/pdf'
                }
            });
        } catch (error) {
            console.error(error)
            enqueueSnackbar('Error subiendo constancia de situación fiscal.', {
                variant: 'error'
            });
            throw new Error()
        }
    }

    const handleUpdate = async (sku, newQuantity, isDelete = false) => {
        if(isLoading){
            return;
        }
        setPreferenceId(null);
        setStepper(0);
        setShippingType(0);
        const newUserCart = JSON.parse(JSON.stringify(userCart));

        if(isDelete){
            newUserCart.items = newUserCart.items.filter(item => item.sku !== sku);
        }else{
            const itemIndx = newUserCart.items.findIndex(item => item.sku === sku);
            newUserCart.items[itemIndx].quantity = Number(newQuantity);
        }

        let result = null;
        try {
            const userId = localStorage.getItem('UID');
            setIsLoading(true);
            if(userCart._id){
                result = await userCartUpdate({_id: newUserCart._id, items: newUserCart.items});
                setPreferenceId(null);
                setIsLoading(false);
                if(!result.result){
                    globalUserDispatchContext({
                        type: 'ADD',
                        userCart: userCart
                    });
                    setIsLoading(false);
                    enqueueSnackbar(result.message, {
                        variant: 'error'
                    });
                    return;
                }
            }

            if(newUserCart.items.length){
                enqueueSnackbar(userId ? result.message: 'Carrito actualizado.', {
                    variant: 'success'
                });
                globalUserDispatchContext({
                    type: 'ADD',
                    userCart: newUserCart
                });
            }else{
                globalUserDispatchContext({
                    type: 'DELETE_CART'
                });
                navigate('/');
            }
            setIsLoading(false);
            
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Ocurrio un problema en el carrito.', {
                variant: 'error'
            });
        }

    }

    const handleMoreInfo = () => {
        navigate(`/products/request`);
    };

    const handleButton = async () => {
        if(isLoading){
            return;
        }
        
        if(!userId && !buyingAsGuest) {
            setBuyingAsGuest(true)
            setShowBuyAsGuestForm(true)
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }

        if(showBuyAsGuestForm && !validateFormTest()){
            return;
        } 
        
        if (stepper === 0){
            setStepper(stepper+1);
            quoteShipping(userCart._id);
            if(showBuyAsGuestForm){
                setShowBuyAsGuestForm(false);
            }
            return;
        }
        setIsLoading(true);
        try {
            const resultValidateQuote = await validateProductStockQuoted(quotedProducts, userCart._id);
            if(resultValidateQuote.canFulfillOrder){
                const itemsToPay = userCart.items.map(item => ({ id: item.product._id, name: item.product.productName, quantity: item.quantity, sku: item.sku, basePrice: getBasePrice(item.product, dollarPrice)  }) );
                const result = await userCartCreatePreference({itemsToPay, userCartId: userCart._id, shippingCost, shipmentOption: shippingType});
                setPreferenceId(result.preference.id)
            }else{
                enqueueSnackbar('Cambios en tiempos de entrega.', {
                    variant: 'warning'
                });
                setShippingCost(0);
                setShippingQuotes([]);
                setQuotedProducts([]);
                setStepper(0);
            }

        } catch (error) {
            console.log(error);
            enqueueSnackbar(error.message, {
                variant: 'error'
            });
        }
        setIsLoading(false);
    };

    const handleShippingType = (e) => {
        setPreferenceId(null);
        const newValue = e.target.value;
        setShippingType(newValue);
        setShippingCost(shippingQuotes[newValue].price);

    }

    const getSubTotal = (cart, dollarValue) => {
        const subTotal =  cart.items.reduce((acc, item) => {
            return acc + truncateDecimal(getBasePrice(item.product, dollarValue) * item.quantity );
        }, 0);
        return truncateDecimal(subTotal);
    }

    const updateForm = (e) => {
        let inputName = e.target.name;
        let value =  e.target.value;

        if ((inputName === 'phone' || inputName === 'postalCode') && !/^\d*$/.test(value)) {
            return;
        }
        let NewValue = {[inputName]: value}
        setBuyAsGuestForm(prev => ({
            ...prev,
            ...NewValue
        }));
        
        buyAsGuestFormErrors[`${inputName}Error`] && setBuyAsGuestFormErrors(prev => ({ ...prev, [`${inputName}Error`]: false }));
    };

    const validateFormTest = () => {
        let newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        requiredValues.forEach( key => {
          if(key == 'email'){
            newErrors[`${key}Error`] = !buyAsGuestForm[`${key}`] || !emailRegex.test(buyAsGuestForm[`${key}`]);
          } else if(key == 'phone'){
            newErrors[`${key}Error`] = !buyAsGuestForm[`${key}`] || !(buyAsGuestForm[`${key}`].length === 10);
          } else {
            newErrors[`${key}Error`] = !buyAsGuestForm[`${key}`];
          }
        });
        
        for (const [key, value] of Object.entries(newErrors)) {
            if (value) {
                enqueueSnackbar(`Campo ${errorValues[key]} incorrecto.`, {
                    variant: 'error'
                });

                setBuyAsGuestFormErrors({
                    ...buyAsGuestFormErrors,
                    ...newErrors
                });

                return false;
            }
        }

        return true;
    };

    useEffect(() => {
        fromPage = searchParams.get('from')
    }, [])

    useEffect(() => {
        if(!observeCfdi && userId)
            return
        if(!userCart._id)
            return
        updateInvoicePreference(userCart._id, invoiceRequested)
            .then(() => {})
            .catch(err => {
                console.error(err)
            })
    }, [invoiceRequested])

    const getInvoiceRequestedStatus = (userCartId) => {
        if(observeCfdi)
            return
        if(!userId) {
            return
        }
        getCartById(userCartId)
            .then(res => {
                setInvoiceRequested(!!res.data.invoiceRequested)
                setTimeout(() => setObserveCfdi(true), 300)
                
            })
            .catch(err => {
                console.error(err)
            })
    }

    const handleNavigate = () => {
        if(showBuyAsGuestForm) {
            setShowBuyAsGuestForm(false)
            setBuyingAsGuest(false)
            setBuyAsGuestForm(buyAsGuestFormBase)
            setStepper(0)
            return
        }
        if(fromPage) {
            navigate(-3)
            return
        }
        navigate(-1)
    }

    const handleLabelClick = () => {
        inputRef.current.click();
        console.log("🚀 ~ handleLabelClick ~ inputRef.current:", inputRef.current)
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFile(file)
        convertToBase64(file)
        inputRef.current.value = '';
    };

    const convertToBase64 = (file) => {
        let reader = new FileReader()
        setFile(file)
        reader.onload = async (e) => {
            setFileData(e.target.result)
        }
        reader.readAsDataURL(file)
    }

    const quoteShippingFormatProvider = (provider) => {

        const carriers = {
            fedex: "Fedex",
            redpack: "Redpack",
            dhl: "DHL",
            paquetexpress: "Paquetexpress",
            sendex: "Sendex",
            quiken: "Quiken",
            tracusa: "Tracusa",
            dostavista: "Dostavista",
            ninetynineminutes: "99 Minutos",
            jtexpress: "Jtexpress"
        };
        
        if(!provider?.length)
            return ''

        let uniqueProviders = [...new Set(provider)].slice(0, 2);
        let text = uniqueProviders.map(h => {
            if(carriers.hasOwnProperty(h.toLowerCase())) {
                return carriers[h.toLowerCase()]
            }
            return h;
        }).join(', ')
        return '(' + text + ')';
    }
    const onChangeBuyAsGuestForm = () => {
        setShippingCost(0);
        setShippingQuotes([]);
        setQuotedProducts([]);
        setShowBuyAsGuestForm(true);
        setStepper(0);
    }


    return (
        <Box sx={{minHeight: '100vh', display:'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ display:'flex', flexDirection: {xs: 'column', md: 'row' }, gap:{xs:'3rem', md:'1.5rem', lg:'2rem'}, pt:{xs:'6.1825rem', sm: '6.5575rem', md: '6.4975rem', lg:'8.75rem' }, px: {md:'3.12rem', lg:'4.5rem', xl:'6.23rem'}, pb: '5rem' }}>
                <Box sx={{display:'flex', flexDirection:'column', width:{ md:'50%', lg:'67%' }, gap:'2rem', px: {xs: '1.25rem', md:0 } }}>
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <IconButton sx={{padding:0}} onClick={handleNavigate}>
                            <NavigateNextIcon sx={{transform: 'rotate(180deg)', ml:{xs:'0px'}, fontSize:{xs:'1.5rem', md:'2rem'}, color: '#FC581F'}}/>
                        </IconButton>
                        <Typography sx={{
                            color: '#303030',
                            fontSize: {xs: '1.25rem', md:'1.5rem' },
                            fontWeight:"bold",
                        }}>
                            {showBuyAsGuestForm ? 'Mi dirección de envio' : 'Carrito de compra'}
                        </Typography>
                    </Box>
                    {showBuyAsGuestForm ?
                        <Grid container rowSpacing={{xs:'1rem', md:'1.5rem'}} columnSpacing={{xs:'0.5rem', md:'1.5rem'}}>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <FormControl variant="standard" fullWidth>
                                    <FormLabel sx={formLabelStyle}>
                                        NOMBRE <Typography sx={requiredLabelStyle}>*</Typography>
                                    </FormLabel>
                                    <TextField 
                                        inputProps={{...inputStyle, maxLength: 50 }}
                                        error={buyAsGuestFormErrors.fullNameError}
                                        placeholder='Nombre Completo'
                                        size='small'
                                        name='fullName' 
                                        value={buyAsGuestForm.fullName}
                                        onChange={updateForm}
                                        disabled={!!stepper}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <FormControl variant="standard" fullWidth>
                                    <FormLabel sx={formLabelStyle}>
                                        CORREO ELECTRÓNICO <Typography sx={requiredLabelStyle}>*</Typography>
                                    </FormLabel>
                                    <TextField 
                                        inputProps={{...inputStyle, maxLength: 80 }}
                                        error={buyAsGuestFormErrors.emailError}
                                        placeholder='Nombre@correo.com'
                                        size='small'
                                        name='email'
                                        value={buyAsGuestForm.email}
                                        onChange={updateForm}
                                        disabled={!!stepper}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <FormControl variant="standard" fullWidth>
                                    <FormLabel sx={formLabelStyle}>
                                        CALLE Y NÚMERO <Typography sx={requiredLabelStyle}>*</Typography>
                                    </FormLabel>
                                    <TextField 
                                        inputProps={{...inputStyle, maxLength: 50 }}
                                        placeholder='ej: Ave. Siempre Viva no. 724'
                                        size='small'
                                        name='address'
                                        error={buyAsGuestFormErrors.addressError}
                                        value={buyAsGuestForm.address}
                                        onChange={updateForm}
                                        disabled={!!stepper}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <FormControl variant="standard" fullWidth>
                                    <FormLabel sx={formLabelStyle}>
                                        COLONIA <Typography sx={requiredLabelStyle}>*</Typography>
                                    </FormLabel>
                                    <TextField 
                                        inputProps={{...inputStyle, maxLength: 36 }}
                                        placeholder='ej: Tlaquepaque'
                                        size='small'
                                        name='colony'
                                        error={buyAsGuestFormErrors.colonyError}
                                        value={buyAsGuestForm.colony}
                                        onChange={updateForm}
                                        disabled={!!stepper}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <FormControl variant="standard" fullWidth>
                                    <FormLabel sx={formLabelStyle}>
                                        CÓDIGO POSTAL <Typography sx={requiredLabelStyle}>*</Typography>
                                    </FormLabel>
                                    <TextField 
                                        inputProps={{...inputStyle, maxLength: 5, type:"text" }}
                                        placeholder='00000'
                                        size='small'
                                        name='postalCode'
                                        error={buyAsGuestFormErrors.postalCodeError}
                                        value={buyAsGuestForm.postalCode}
                                        onChange={updateForm}
                                        disabled={!!stepper}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <FormControl variant="standard" fullWidth>
                                    <FormLabel sx={formLabelStyle}>
                                        TELÉFONO <Typography sx={requiredLabelStyle}>*</Typography>
                                    </FormLabel>
                                    <TextField 
                                        inputProps={{
                                            ...inputStyle,
                                            maxLength: 10,
                                            type:"tel",
                                            pattern:"[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <p>+52</p>
                                              </InputAdornment>
                                            ),
                                          }}
                                        placeholder='0000000000'
                                        size='small'
                                        name='phone'
                                        error={buyAsGuestFormErrors.phoneError}
                                        value={buyAsGuestForm.phone}
                                        onChange={updateForm}
                                        disabled={!!stepper}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <FormControl fullWidth>
                                    <FormLabel focused={false} sx={formLabelStyle}>
                                        ESTADO <Typography sx={requiredLabelStyle}>*</Typography>
                                    </FormLabel>
                                    <Select
                                        sx={ inputStyle.sx }
                                        size='small'
                                        error={buyAsGuestFormErrors.regionError}
                                        value={buyAsGuestForm.region}
                                        name='region'
                                        onChange={updateForm}
                                        disabled={!!stepper}
                                    >
                                        {!!Object.keys(MexicanStates) && Object.values(MexicanStates).map( (mexicanState, indx) => (
                                            <MenuItem sx={inputStyle.sx} key={indx} value={mexicanState}>{mexicanState}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <FormControl variant="standard" fullWidth>
                                    <FormLabel sx={formLabelStyle}>
                                        CIUDAD <Typography sx={requiredLabelStyle}>*</Typography>
                                    </FormLabel>
                                    <TextField 
                                        inputProps={{...inputStyle, maxLength: 36 }}
                                        placeholder='Escribe tu ciudad.'
                                        size='small'
                                        name='city'
                                        error={buyAsGuestFormErrors.cityError}
                                        value={buyAsGuestForm.city}
                                        onChange={updateForm}
                                        disabled={!!stepper}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="standard" fullWidth>
                                    <FormLabel sx={formLabelStyle}>
                                        REFERENCIAS <Typography sx={optionalLabelStyle}> (Opcional)</Typography>
                                    </FormLabel>
                                    <TextField
                                        inputProps={{...inputStyle, maxLength: 25 }}
                                        placeholder='Referencia de vivienda'
                                        size='small'
                                        name='references'
                                        value={buyAsGuestForm.references}
                                        onChange={updateForm}
                                        disabled={!!stepper}
                                    />
                                    <Typography sx={{color: '#A1A1A1', fontSize: '0.875rem', pt:'0.4rem', lineHeight: {xs: '0.875rem', md: '1rem'}}}>*25 caracteres como máximo</Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <FormControl variant="standard" fullWidth>
                                    <FormLabel sx={formLabelStyle}>
                                        USO DE CFDI
                                    </FormLabel>
                                    <TextField
                                        inputProps={{...inputStyle, maxLength: 36 }}
                                        placeholder='Usos de CFDI para RIF'
                                        size='small'
                                        name='cfdiUse'
                                        value={buyAsGuestForm.cfdiUse}
                                        disabled={!!stepper}
                                        onChange={updateForm}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="standard" fullWidth>
                                    <FormLabel sx={{...formLabelStyle, paddingBottom: !file ? '0.625rem': '0rem'}}>
                                        CONSTANCIA DE SITUACIÓN FISCAL (CSF)<Typography sx={optionalLabelStyle}> (Opcional)</Typography>
                                    </FormLabel>
                                    {file && (
                                        <Box sx={{ paddingBottom: '10px' }}>
                                            <FileDownloader file={file}/>
                                        </Box>
                                    )}
                                    <>
                                        <input type="file" style={{display:'none'}} ref={inputRef} accept="application/pdf" onChange={handleFileChange} />
                                        <Button 
                                            sx={{ 
                                                fontSize: '0.8125rem',
                                                fontWeight:'700',
                                                background: '#FFF',
                                                color: '#FC581F',
                                                textTransform:'none',
                                                minWidth: {xs: '64px',lg: '64px'},
                                                minHeight: {xs: '44px',lg: '44px'},
                                                maxWidth: {xs: '200px',lg: '200px'},
                                                p:{xs:' 0.688rem 0.688rem', lg:'6px 16px'},
                                                borderRadius:'62.5rem',
                                                boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)',
                                                '& .MuiButton-startIcon':{
                                                    marginRight: {xs:'0', lg: '8px'},
                                                    marginLeft: {xs:'0', lg: '0px'},
                                                },
                                                '&:hover': { 
                                                    backgroundColor: '#FAFAFA'
                                                },
                                                // display:{ xs:'none', md:'inline-flex' },
                                            }}
                                            variant="contained" 
                                            startIcon={<AddIcon sx={{fontSize:'1.5rem !important'}} /> }
                                            disabled={!!stepper}
                                            onClick={handleLabelClick}
                                        >
                                            <Typography sx={{ display:{xs:'block', lg:'block'}, fontSize: '0.8125rem', fontWeight:'700' }}>
                                            Agregar archivo PDF
                                            </Typography>
                                        </Button>
                                        </>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <p style={{ color: "#A8A7A7" }}>
                                    *Todos los campos son requeridos para realizar la compra.
                                </p>
                            </Grid>
                            
                        </Grid> :
                        <Box sx={{display:'flex', flexDirection:'column', gap: {xs:'0.62rem', lg:'1.5rem'} }}>
                            <Box sx={{display: {xs: 'none', lg: 'block'}}}>
                                <Box sx={{display:'flex', gap:'0.75rem', pb:'0.75rem'}}>
                                    <Typography sx={{...style.header, width: '47%', pr:'1.5rem'}}>ARTÍCULO</Typography>
                                    <Box sx={{display:'flex', gap:'0.75rem', width: '53%' }} >
                                        <Typography sx={style.header}>PRECIO</Typography>
                                        <Typography sx={style.header}>CANTIDAD</Typography>
                                        <Typography sx={style.header}>SUBTOTAL</Typography>
                                        <Box sx={{width:'1rem'}}></Box>
                                    </Box>
                                </Box>
                                <Box sx={{height: '0.0625rem', width:'100%', background:'#BABABA'}}/>
                            </Box>
                            {
                                userCart.items.map((item, indx) => (
                                    <React.Fragment key={indx}>
                                        <ItemCart key={item.sku} itemCart={item} itemCartStock={itemsCartStock[indx]} dollarPrice={dollarPrice} isLoading={isLoading} handleUpdate={handleUpdate}/>
                                        {
                                            (indx+1 < userCart.items.length) && 
                                            <Box key={`indx${indx}`} sx={{height: '0.0625rem', width:'100%', background:'#BABABA'}}/>
                                        }
                                    </React.Fragment>
                                ))
                            }
                        </Box>
                    }
                </Box>
                <Box sx={{ width:{ md:'50%', lg:'33%' }, px: {xs:'2.34rem', md:0}, display: 'flex', flexDirection: 'column', gap: '2.38rem' }}>
                    <Box sx={{display: 'flex', flexDirection: 'column', padding: '1.875rem 2rem', gap: '2rem', background: '#fff' }}>
                        <Typography sx={{color: '#303030', fontSize:{xs:'1.125rem', lg:'1.5rem', fontWeight:'bold' } }}>Resumen de compra:</Typography>
                        
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.875rem'}}>
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '0.75rem'}}>
                                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <Typography sx={style.text1}>¿Solicitar factura?</Typography>
                                    <Tooltip title={((!user.cfdiUploaded || !user.cfdiUse) && !!userId) || (!userId && (!fileData || !buyAsGuestForm.cfdiUse)) ? "Es necesario cargar previamente tu Constancia de Situación Fiscal y especificar el uso de CFDI para solicitar factura en tu compra" : ''} arrow>
                                        <div>
                                            <IOSSwitch
                                                sx={{ m: 0}} 
                                                checked={invoiceRequested}
                                                onChange={handleChange}
                                                disabled={((!user.cfdiUploaded || !user.cfdiUse) && !!userId) || (!userId && (!fileData || !buyAsGuestForm.cfdiUse)) }
                                                inputProps={{ 'aria-label': 'controlled' }}  
                                            />    
                                        </div>
                                    </Tooltip>
                                </Box>
                                <Typography sx={{
                                    color: '#7C7B7B',
                                    lineHeight: '1rem',
                                    fontSize: '0.8rem',
                                    opacity: '0.8',
                                }}>
                                    *La constancia de situación fiscal deberá contener el domicilio fiscal actual y se recomienda tenerla actualizada para poder solicitar la factura de tu compra.
                                </Typography>
                                {userId && (
                                    <div
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => navigate('/user/' + userId + '?editOption=cfdi')}
                                    >
                                        <Box
                                            sx={{
                                                display:'flex',
                                                alignItems: 'center',
                                                gap: { xs:'0.2rem', lg:'0.2rem' }
                                            }}
                                        >
                                            <FileIcon />
                                            <Typography style={{ color: '#FC581F' }}>
                                                Actualizar CDFI
                                            </Typography>
                                        </Box>
                                    </div>
                                )}
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '0.75rem'}}>
                                <Typography sx={style.text1}>Dirección de envío:</Typography>
                                {
                                    userAddress ?
                                        <Typography sx={style.text1}>{userAddress}</Typography>
                                        :
                                        buyingAsGuest ? <Typography sx={style.text1}>{`${buyAsGuestForm.address} ${buyAsGuestForm.colony} ${buyAsGuestForm.postalCode}`}</Typography> : ''

                                }
                                {(userId  || (buyingAsGuest && !showBuyAsGuestForm) )&& (
                                    <div
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => { !isLoading && !userId && buyAsGuestForm ? onChangeBuyAsGuestForm()  : navigate('/user/' + userId)} }
                                    >
                                        <Box
                                            sx={{
                                                display:'flex',
                                                alignItems: 'center',
                                                gap: { xs:'0.2rem', lg:'0.2rem' },
                                                opacity: isLoading ? 0.5 : 1
                                            }}
                                        >
                                            <BoxIcon />
                                            <Typography style={{ color: '#FC581F' }}>
                                                Cambiar dirección
                                            </Typography>
                                        </Box>
                                    </div>
                                )}
                            </Box>
                            <Box sx={{height: '0.0625rem', width:'100%', background:'#BEBDBD'}}/>
                            <Box sx={style.rows}>
                                <Typography sx={style.text1}>Subtotal</Typography>
                                <Typography sx={style.text1}>{`${currencyFormat(subtotal)} MXN`}</Typography>
                            </Box>
                            <Box sx={{height: '0.0625rem', width:'100%', background:'#BEBDBD'}}/>
                            <Box sx={style.rows}>
                                <Typography sx={{...style.text1}}>Envío*</Typography>
                                <Typography sx={{...style.text1}}>{isQuotingShipping ? `calculando...`: `${currencyFormat(shippingCost)} MXN`}</Typography>                                
                            </Box>
                            {shippingCost === null && <Typography sx={{...style.text1, color: 'red'}}>No es posible enviar a esta dirección, verifique sus datos.</Typography>}
                            <Box>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={shippingType}
                                    onChange={handleShippingType}
                                >
                                    <FormControlLabel
                                        disabled={isLoading || isQuotingShipping || !shippingQuotes.length }
                                        sx={style.radioLabel}
                                        value="0"
                                        control={<Radio sx={style.radioIcon} />}
                                        label={`Envío estándar ${quoteShippingFormatProvider(shippingQuotes[0]?.provider)}`}
                                    />
                                    <FormControlLabel
                                        disabled={isLoading || isQuotingShipping || !shippingQuotes.length }
                                        sx={style.radioLabel}
                                        value="1"
                                        control={<Radio sx={style.radioIcon} />}
                                        label={`Envío express ${quoteShippingFormatProvider(shippingQuotes[1]?.provider)}`}
                                    />
                                    <FormControlLabel
                                        disabled={isLoading || isQuotingShipping || !shippingQuotes.length }
                                        sx={style.radioLabel}
                                        value="2"
                                        control={<Radio sx={style.radioIcon} />}
                                        label={`Envío premium ${quoteShippingFormatProvider(shippingQuotes[2]?.provider)}`}
                                    />
                                </RadioGroup>
                            </Box>
                            <Box sx={{height: '0.0625rem', width:'100%', background:'#303030'}}/>
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                                <Box sx={style.rows}>
                                    <Typography sx={style.text2}>Total</Typography>
                                    <Typography sx={style.text2}>{`${currencyFormat(subtotal + shippingCost)} MXN`}</Typography>
                                </Box>
                                <Typography sx={style.text3}>
                                    *Los gastos de envío están sujetos a cambios, impuestos incluidos.
                                </Typography>
                                <Typography sx={style.text3}>
                                    *La "entrega estimada" es una estimación y puede variar. Tu pedido podría llegar antes o después de la fecha prevista.
                                </Typography>
                                <Typography sx={style.text3}>
                                    *La fecha de entrega estimada se calcula a partir de la recolección del pedido.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '1.88rem', px: '2rem' }}>
                        {   !!preferenceId ?
                            ( <MercadoPagoButton preferenceId={preferenceId}/>)
                            : (<Button
                            variant='contained'
                            disabled={isLoading || isQuotingShipping || (!!stepper && !shippingCost)}
                            onClick={handleButton}
                            sx={{
                                fontSize: '1rem',
                                fontWeight:'bold',
                                backgroundColor: '#FC581F' ,
                                textTransform:'none',
                                padding: '0.75rem 1rem',
                                boxShadow: 0,lineHeight: '1.125rem',
                                letterSpacing: '0.01563rem',
                                '&:hover': { 
                                    backgroundColor: '#FC581F'
                                }
                            }}
                        >
                            {!userId && !buyingAsGuest ? 
                                <span>
                                    {'Comprar como'}
                                    <b> Invitado</b>
                                </span>
                                : !stepper ? 'Cotizar envío' : 'Proceder al pago'
                            }
                            </Button>)
                        }
                        {!userId && !buyingAsGuest && (
                            <div>
                                <p style={{ textAlign: 'center', margin: 0, marginBottom: '10px' }}>
                                    O
                                </p>
                                <Divider>
                                    <NavLink to={'/login'} style={{ color: '#FC581F' }}>
                                        {/* <a href="" > */}
                                            Inicia sesión
                                        {/* </a> */}
                                    </NavLink>
                                </Divider>
                            </div>
                        )}
                        <Button
                            variant='outlined'
                            disabled={isLoading || isQuotingShipping }
                            onClick={handleMoreInfo}
                            sx={{
                                fontSize: '1rem',
                                color: '#FC581F',
                                fontWeight:'bold',
                                textTransform:'none',
                                padding: '0.75rem 1rem',
                                boxShadow: 0,
                                border: '1px solid #FC581F',
                                lineHeight: '1.125rem',
                                letterSpacing: '0.01563rem',
                                '&:hover': { 
                                    border: '1px solid #FC581F',
                                }
                            }}
                        >
                            Solicitar más información
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Footer/>
        </Box>
    )
}

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#FC581F' : '#FC581F',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

export default CartPage