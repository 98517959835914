import axios from '../utils/axios';

const SERVICE_URL = '/exchangeRates';

export const exchangeRatesByCode = async (code) => {
    try {
        let response = await axios.get(`${SERVICE_URL}/bycode?code=${code}`);
        return response.data;
    } catch (error) {
        return {result: false, message: 'ocurrio un problema'};
    }
}
