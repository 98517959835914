import { useState, useEffect} from 'react'
import { Box, Typography, Select, MenuItem } from '@mui/material';


const styles = {
    titleInput: {
        color: '#A1A1A1',
        fontSize: '1rem',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        pb:'1.5rem'
    },
    selectInput: {
        backgroundColor: '#FFF'
    }
}

const InputSelect = ({level, textTitle, options, defaultValue, handleOnchange}) => {
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(options[defaultValue]);
    
    }, [options, defaultValue])
    

    const handleValue = (e) => {
        const newValue = e.target.value
        const indexOption = options.findIndex(option => option === newValue);
        setValue(newValue);
        handleOnchange(textTitle, newValue, level, indexOption);
    }

    return (
        <Box sx={{width:'100%'}}>
            <Typography sx={styles.titleInput}>{textTitle}</Typography>
            <Select
            name='value'
            size='small'
            fullWidth
            sx={styles.selectInput}
            value={value}
            onChange={handleValue}
            >
                {options.map((valueItem, indx) => {
                return (
                    <MenuItem key={indx} value={valueItem}>{valueItem}</MenuItem>
                )
                })}
            </Select>
        </Box>
    );
}

export default InputSelect