import React from "react";
import { Box, Typography } from '@mui/material';
import ButtonStyled from '../ButtonStyled';

import { formatNumberToCurrency, sumExtraPrice, truncateDecimal } from '../../utils/utils';
import { UserContext } from '../../context/UserContext';

const styles = {
    card:{
        borderRadius: '0.25rem',
        background: '#FFF',
        display: 'flex',
        padding: {xs:'0rem 1.25rem 2.31rem 1.25rem', lg:'1.88rem'},
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    titleProduct: {
        color: '#303030',
        textAlign: 'center',
        fontSize: {xs:'1.125rem', lg:'1.5625rem'},
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
        pt: '1.88rem',
        pb:{xs: '0.75rem', lg:'1.88rem'}
    },
    availableStock: { fontSize: {xs: '0.875rem' , md:'1rem'}, fontStyle: 'normal', fontWeight: 'bold', color: '#FC581F', textAlign: 'center', pb: '0.5rem'},
    unAvailableStock: { fontSize: {xs: '0.875rem' , md:'1rem'}, fontStyle: 'normal', fontWeight: '400', textAlign: 'center', pb: '0.5rem'}
}

const ProductDetails = ({text, value, bold = false}) => {
    return (
        <Box sx={{display:'flex', justifyContent: 'space-between', gap:'0.625rem'}}>
            <Typography sx={{ fontSize: {xs: '0.875rem' , md:'1rem'}, fontStyle: 'normal', fontWeight: '400',}}>{text}</Typography>
            <Typography sx={{ fontSize: {xs: '0.875rem' , md:'1rem'}, textAlign:'end', fontWeight: bold ? '700' : '600'}}>{value}</Typography>
        </Box>
    )
}

const ProductResumeCard = ({product, selectedVariant, dollarPrice, buttonText1, buttonText2, handleButton1, handleButton2, customStyle1}) => {
    const { domain } = React.useContext(UserContext);
    const ProductPrice = truncateDecimal(Number(product.price) * Number(dollarPrice));
    const totalExtraPrice = truncateDecimal(sumExtraPrice(selectedVariant) * Number(dollarPrice));
    const totalPrice = ProductPrice + totalExtraPrice;
    return (
        <Box sx={styles.card}>
            <Box sx={{width: '100%'}}>
                <Box sx={{ height:{xs: '9.375rem', lg:'19.9375rem'}, display:'flex', justifyContent:'center', alignItems: 'center' }}>
                    {
                        !!product.images.length
                        ?
                            <img
                                style={{
                                    objectFit:'contain',
                                    height:'100%' 
                                }}
                                src={`${domain}${product.images[0].url}`}
                            /> 
                        : <Typography>Sin Imagen</Typography>
                    }
                </Box>
                <Typography sx={styles.titleProduct}> {product.productName}</Typography>
                {(selectedVariant.stock > 0) ?
                 <Typography sx={styles.availableStock}> {selectedVariant.stock} Unidades en Stock </Typography>
                 : <Typography sx={styles.unAvailableStock}> Tiempo de entrega estimado:  {!!selectedVariant.extraDeliveryTime ? selectedVariant.extraDeliveryTime : '-'} {!!selectedVariant.extraDeliveryTime && selectedVariant.extraDeliveryTime == 1 ? 'semana': 'semanas'}</Typography>
                }
                <Box sx={{background: '#FC581F', height:'0.0625rem'}}/>
            </Box>
            <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap: '0.62rem', pt:'3.12rem'}}>
                <ProductDetails text={'ID Único de Producto'} value={selectedVariant.sku ? selectedVariant.sku : product.code}/>
                <ProductDetails text={'Precio Base'} value={`${formatNumberToCurrency(ProductPrice)} MXN`}/>
                <ProductDetails text={'Personalización'} value={`${formatNumberToCurrency(totalExtraPrice)} MXN`}/>
                <ProductDetails text={'Total'} value={`${formatNumberToCurrency(totalPrice)} MXN`}/>
            </Box>
            <Box sx={{width:'100%', display:'flex', justifyContent: 'space-between', gap: '0.75rem', pt: '1.88rem' }}>
                <ButtonStyled text={buttonText1} primary={false} customStyle={customStyle1} handleClick={handleButton1}/>
                <ButtonStyled text={buttonText2} handleClick={handleButton2}/>
            </Box>
        </Box>
    )
}

export default ProductResumeCard