import { useContext, useEffect, useState } from 'react';
import Badge from '@mui/material/Badge';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { UserContext, UserDispatchContext } from '../context/UserContext';
import { NavLink } from 'react-router-dom';

const ShoppingCart = () => {
  const globalUserDispatchContext = useContext(UserDispatchContext);
  const globalUserContext = useContext(UserContext);

  useEffect(() => {
    globalUserDispatchContext({type: 'INIT_CART'})
  }, [])
  
  return (
    <NavLink style={{ textDecoration: 'none' }} to={!globalUserContext.itemsCount ? '/' : '/cart'}>
      <Badge
        badgeContent={globalUserContext.itemsCount}
        max={9}
        sx={{ mt: { xs: 0 }, '& .MuiBadge-badge': { backgroundColor: { xs: '#FC581F' }, color: { xs: 'white' } } }}
      >
        <ShoppingCartOutlinedIcon sx={{ color: { xs: 'white' } }} />
      </Badge>
    </NavLink>
  );
};

export default ShoppingCart;
