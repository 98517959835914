import { useEffect, useState } from 'react'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getPresignedUrlDownloadForCFDI, getPresignedUrlForCFDI, getUser, userCreate, userEditAddress, userUpdateCfdiStatus } from '../services/userService';
import ButtonStyled from '../components/ButtonStyled';
import { Box, Button, FormControl, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import bgimg from '../assets/bgimg.jpg'
import LogoBlack from '../components/Icons/LogoBlack';
import FileDownloader from '../components/FileDownloader';
import { MexicanStates } from '../utils/enums';
import { useRef } from 'react';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios'

const formLabelStyle = {color: '#A1A1A1', fontWeight:'bold', fontSize:{xs: '1rem', display: 'flex'} };
const inputStyle = {sx: { fontSize:{xs: '1rem', md: '1rem'}, background:'#FFFEFC', borderRadius: '0.25rem' }};
const formControlStyle = {
    '& .MuiOutlinedInput-root': {
        borderRadius: '0.25rem',
        backgroundColor:'white',
    },
};

const requiredLabelStyle = {color: '#FC581F', fontWeight:'bold'};
const optionalLabelStyle = {color: '#A1A1A1', fontSize: '0.875rem', pl:'0.1rem'};

const defaultErrorValues = {
    emailError: false,
    passwordError: false,
    passwordConfirmError: false,
    nameError: false,
    lastNameError: false,
    phoneError: false,
    addressError: false,
    colonyError: false,
    postalCodeError: false,
    regionError: false,
    cityError: false,
    cfdiUse: false,
};
const requiredValues = ['email','password', 'passwordConfirm', 'name', 'lastName','phone','address','colony','postalCode','region','city' ];
const editingUserRequiredValues = ['email',  'name', 'lastName','phone','address','colony','postalCode','region','city' ];

const errorValues = {
    emailError: 'Email',
    passwordError: 'Contraseña',
    passwordConfirmError: 'Confirmar contraseña',
    nameError: 'Nombre',
    lastNameError: 'Apellido',
    phoneError: 'Teléfono',
    addressError: 'Calle y número',
    colonyError: 'Colonia',
    postalCodeError: 'Código postal',
    regionError: 'Estado',
    cityError: 'Ciudad',
};

const RegisterPage = () => {
    const params = useParams()
    const [userForm, setUserForm] = useState({
        email: '',
        password: '',
        passwordConfirm: '',
        name: '',
        lastName: '',
        phone: '',
        address: '',
        colony: '',
        postalCode: '',
        region: '',
        city: '',
        references: '',
        cfdiUse: '',
    });
    
    const [errors, setErrors] = useState(defaultErrorValues);
    const [isLoading, setIsLoading] = useState(false);
    const [editingUser, setEditingUser] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [file, setFile] = useState(null)
    const [fileData, setFileData] = useState(null)
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const inputRef = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [editingCfdi, setEditingCfdi] = useState(false)

    const { email,  password, passwordConfirm, name, lastName, phone, address, colony, postalCode, region, city, references } = userForm;
    const { emailError, passwordError, passwordConfirmError, nameError, lastNameError, phoneError, addressError, colonyError, postalCodeError, regionError, cityError, } = errors;

    const handleClickShowPassword = (option) => {
        option
        ? setShowPasswordConfirm(!showPasswordConfirm)
        : setShowPassword(!showPassword);
    };

    const updateForm = (e) => {
        let inputName = e.target.name;
        let value =  e.target.value;

        if ((inputName === 'phone' || inputName === 'postalCode') && !/^\d*$/.test(value)) {
        return;
        }
        let NewValue = {[inputName]: value}
        setUserForm({
            ...userForm,
            ...NewValue
        });
        
        errors[`${inputName}Error`] && setErrors({ ...errors, [`${inputName}Error`]: false });
    };

    const validateForm = () => {
        let newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let _requiresValues = editingUser ? editingUserRequiredValues : requiredValues

        _requiresValues.forEach( key => {
          if(key == 'email'){
            newErrors[`${key}Error`] = !userForm[`${key}`] || !emailRegex.test(userForm[`${key}`]);
          } else if(key == 'phone'){
            newErrors[`${key}Error`] = !userForm[`${key}`] || !(userForm[`${key}`].length === 10);
          } else {
            newErrors[`${key}Error`] = !userForm[`${key}`];
          }
        });
      
        for (const [key, value] of Object.entries(newErrors)) {
            if (value) {
                enqueueSnackbar(`Campo ${errorValues[key]} incorrecto.`, {
                    variant: 'error'
                });

                setErrors({
                    ...errors,
                    ...newErrors
                });
                return false;
            }
        }

        if(editingUser) {
            return true
        }

        if(password !== passwordConfirm){
            newErrors[`passwordConfirmError`] = true;
            enqueueSnackbar(`Las contraseñas no coinciden.`, {
                variant: 'error'
            });

            setErrors({
                    ...errors,
                    ...newErrors
            });
            return false;
        }

        if(!validatePassword(password)){
            newErrors[`passwordConfirmError`] = true;
            enqueueSnackbar(`La contraseña debe tener al menos 8 caracteres, una letra mayúscula y un numero.`, {
                variant: 'error'
            });

            setErrors({
                    ...errors,
                    ...newErrors
            });
            return false;
        }
      
        return true;
    };

    const validatePassword = (str) => {
        if (str.length < 8) {
          return false;
        }
      
        if (!/[A-Z]/.test(str)) {
          return false;
        }
      
        if (!/\d/.test(str)) {
          return false;
        }
      
        return true;
    };

    const convertToBase64 = (file) => {
        let reader = new FileReader()
        setFile(file)
        reader.onload = async (e) => {
            setFileData(e.target.result)
        }
        reader.readAsDataURL(file)
    }
    const validateEditCfdi = () => {
        let requiredValues = ['cfdiUse'] 
        let newErrors = {}
        requiredValues.forEach(req => {
            if(!userForm[req])
                newErrors[req] = true
        })
        setErrors(prev => ({
            ...prev,
            ...newErrors
        }));
        if(Object.keys(newErrors)?.length) {
            enqueueSnackbar(`Campos incorrectos.`, {
                variant: 'error'
            });
            return false
        }
        return true
    }

    const OnSubmitForm = async () => {
        if(isLoading){
          return;
        }
        if(editingCfdi) {
            if(!validateEditCfdi)
                return
            await uploadCfdi(params.id)
            return
        }
        if(!validateForm()){
          return;
        }
        let resultForm
        if(editingUser) {
            try {
                await userEditAddress(userForm)
                navigate('/cart?from=1')
            } catch (error) {
                console.error(error);
                enqueueSnackbar('Error actualizando la dirección', {
                    variant: 'error'
                  });
            }
        } else {
            setIsLoading(true);
            resultForm = await userCreate({ ...userForm, cfdiUploaded: !!file });
        }
        if(file) {
            await uploadCfdi(editingUser ? params.id : resultForm.userId)
        }
        setIsLoading(false);
        if(resultForm.result){
            enqueueSnackbar(resultForm.message, {
              variant: 'success',
              style: { 
                Width: '100%',
                justifyContent: 'center'
              }
            });
            navigate('/login');
        }else{
          enqueueSnackbar(resultForm.message, {
            variant: 'error'
          });
        }
      };

    useEffect(() => {
        if(params.id === 'new') {
            return
        }
        if(searchParams.get('editOption') === 'cfdi') {
            setEditingCfdi(true)
        }
        setEditingUser(true)
        getUser(params.id)
            .then(res => {
                setUserForm(res.user)
            })
            .catch(err => {
                console.error(err)
            })
    }, [])

    const handleLabelClick = () => {
        inputRef.current.click();
        console.log("🚀 ~ handleLabelClick ~ inputRef.current:", inputRef.current)
    };
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFile(file)
        convertToBase64(file)
        inputRef.current.value = '';
    };

    const uploadCfdi = async (userId) => {
        let presignedUrl
        try {
            let res = await getPresignedUrlForCFDI(userId)
            presignedUrl = res.data
        } catch (error) {
            console.error(error)
            enqueueSnackbar('Error subiendo constancia de situación fiscal.', {
                variant: 'error'
            });
            return
        }
        try {
            const base64Response = await fetch(fileData);
            const blob = await base64Response.blob();
            await axios.put(presignedUrl, blob, {
                headers: {
                    'Content-Type': 'application/pdf'
                }
            });
        } catch (error) {
            console.error(error)
            enqueueSnackbar('Error subiendo constancia de situación fiscal.', {
                variant: 'error'
            });
            return
        }
        if(editingCfdi) {
            try {
                await userUpdateCfdiStatus(params.id, userForm.cfdiUse)
            } catch (error) {
                console.error(error)
            }
        }
        enqueueSnackbar('Constancia de situacion fiscal actualizada.', {
            variant: 'success'
        });
        if(editingCfdi) {
            navigate('/cart?from=1')
        }
    }

    const downloadCfdi = async () => {
        try {
            let res = await getPresignedUrlDownloadForCFDI(params.id)
            window.open(res.data, '_blank');
        } catch (error) {
            console.error(error)
            enqueueSnackbar('Error descargando constancia de situación fiscal.', {
                variant: 'error'
            });
        }
    }

  return (
    <>
        <Box sx={{ 
            px:{xs: '1.5rem', md: '13.31rem', lg: '20rem', xl: '28.5rem'}, display:'flex', flexDirection:'column', justifyContent:'center', minHeight:'100vh',
            background: `linear-gradient(180deg, #F8F8F8 100%, rgba(248, 248, 248, 0.00) 2%) no-repeat`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}>
            <Box sx={{ display:'flex', flexDirection:'column', alignItems:'center' }}>
                <Box sx={{ width: {xs: '12.5rem', md:'15.625rem', lg: '17rem', xl:'18.75rem'}, pt:'0.5rem' }}>
                    <LogoBlack/>
                </Box>
                <Box sx={{py:{xs: '1.5rem', md:'2rem'}}}>
                    <Box sx={{width: '10.09375rem', height: '0.0625rem', background: '#D9D9D9'}}/>
                </Box>
                <Typography sx={{color: '#303030', fontSize: {xs: '1.5rem', md:'1.75', lg: '2rem'}, fontWeight:'bold', textAlign:'center' }}>
                    {editingUser ? 'Mi dirección' : 'Crear cuenta'}
                </Typography>
                <Typography sx={{color: '#303030', fontSize: {xs: '0.875rem', md:'1rem'}, textAlign:'center', pt: '0.75rem', pb: '2rem'}}>*Campos obligatorios</Typography>
            </Box>
            <Grid container rowSpacing={{xs:'1rem', md:'1.5rem'}} columnSpacing={{xs:'0.5rem', md:'1.5rem'}}>
                <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel sx={formLabelStyle}>
                            CORREO ELECTRÓNICO <Typography sx={requiredLabelStyle}>*</Typography>
                        </FormLabel>
                        <TextField 
                            inputProps={{...inputStyle, maxLength: 80 }}
                            error={emailError}
                            placeholder='Escribe tu correo electrónico'
                            size='small'
                            name='email'
                            value={email}
                            disabled={editingUser}
                            onChange={updateForm}
                        />
                    </FormControl>
                </Grid>
                {!editingUser && (
                    <Grid item xs={12}>
                        <FormControl variant="standard" fullWidth sx={formControlStyle} >
                            <FormLabel focused={false} sx={formLabelStyle}>
                                CONTRASEÑA <Typography sx={requiredLabelStyle}>*</Typography>
                            </FormLabel>
                            <OutlinedInput
                                inputProps={inputStyle}
                                fullWidth
                                size="small"
                                type={showPassword ? 'text' : 'password'}
                                name='password'
                                value={password}
                                placeholder='*******'
                                onChange={updateForm}
                                error={passwordError}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleClickShowPassword(0)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText>La contraseña debe tener al menos 8 caracteres, una letra mayúscula y un numero.</FormHelperText>
                        </FormControl>
                    </Grid>
                )}
                {!editingUser && (
                    <Grid item xs={12}>
                        <FormControl variant="standard" fullWidth sx={formControlStyle}>
                            <FormLabel focused={false} sx={formLabelStyle}>
                                CONFIRMAR CONTRASEÑA <Typography sx={requiredLabelStyle}>*</Typography>
                            </FormLabel>
                            <OutlinedInput
                                inputProps={inputStyle}
                                fullWidth
                                size="small"
                                type={showPasswordConfirm ? 'text' : 'password'}
                                name='passwordConfirm'
                                value={passwordConfirm}
                                placeholder='*******'
                                onChange={updateForm}
                                error={passwordConfirmError}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleClickShowPassword(1)}
                                            edge="end"
                                        >
                                            {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={6}>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel sx={formLabelStyle}>
                            NOMBRE <Typography sx={requiredLabelStyle}>*</Typography>
                        </FormLabel>
                        <TextField 
                            inputProps={{...inputStyle, maxLength: 50 }}
                            error={nameError}
                            placeholder='Escribe tu nombre'
                            size='small'
                            name='name' 
                            value={name}
                            disabled={editingUser}
                            onChange={updateForm}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel sx={formLabelStyle}>
                            APELLIDO <Typography sx={requiredLabelStyle}>*</Typography>
                        </FormLabel>
                        <TextField 
                            inputProps={{...inputStyle, maxLength: 50 }}
                            placeholder='Escribe tu apellido'
                            size='small'
                            name='lastName'
                            error={lastNameError}
                            value={lastName}
                            disabled={editingUser}
                            onChange={updateForm}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel sx={formLabelStyle}>
                            TELÉFONO <Typography sx={requiredLabelStyle}>*</Typography>
                        </FormLabel>
                        <TextField 
                            inputProps={{...inputStyle, maxLength: 10, type:"tel", pattern:"[0-9]{3}-[0-9]{2}-[0-9]{3}"}}
                            placeholder='0000000000'
                            size='small'
                            name='phone'
                            error={phoneError}
                            value={phone}
                            disabled={editingUser}
                            onChange={updateForm}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel sx={formLabelStyle}>
                            CALLE Y NÚMERO <Typography sx={requiredLabelStyle}>*</Typography>
                        </FormLabel>
                        <TextField 
                            inputProps={{...inputStyle, maxLength: 50 }}
                            placeholder='Ej. Ave. principal no. 742'
                            size='small'
                            name='address'
                            error={addressError}
                            value={address}
                            disabled={editingCfdi}
                            onChange={updateForm}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel sx={formLabelStyle}>
                            COLONIA <Typography sx={requiredLabelStyle}>*</Typography>
                        </FormLabel>
                        <TextField 
                            inputProps={{...inputStyle, maxLength: 36 }}
                            placeholder='Escribe tu colonia.'
                            size='small'
                            name='colony'
                            error={colonyError}
                            value={colony}
                            disabled={editingCfdi}
                            onChange={updateForm}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel sx={formLabelStyle}>
                            CÓDIGO POSTAL <Typography sx={requiredLabelStyle}>*</Typography>
                        </FormLabel>
                        <TextField 
                            inputProps={{...inputStyle, maxLength: 5, type:"text" }}
                            placeholder='00000'
                            size='small'
                            name='postalCode'
                            error={postalCodeError}
                            value={postalCode}
                            disabled={editingCfdi}
                            onChange={updateForm}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <FormLabel focused={false} sx={formLabelStyle}>
                            ESTADO <Typography sx={requiredLabelStyle}>*</Typography>
                        </FormLabel>
                        <Select
                            sx={ inputStyle.sx }
                            size='small'
                            error={regionError}
                            value={region}
                            disabled={editingCfdi}
                            name='region'
                            onChange={updateForm}
                        >
                            {!!Object.keys(MexicanStates) && Object.values(MexicanStates).map( (mexicanState, indx) => (
                                <MenuItem sx={inputStyle.sx} key={indx} value={mexicanState}>{mexicanState}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel sx={formLabelStyle}>
                            CIUDAD <Typography sx={requiredLabelStyle}>*</Typography>
                        </FormLabel>
                        <TextField 
                            inputProps={{...inputStyle, maxLength: 36 }}
                            placeholder='Escribe tu ciudad.'
                            size='small'
                            name='city'
                            error={cityError}
                            disabled={editingCfdi}
                            value={city}
                            onChange={updateForm}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel sx={formLabelStyle}>
                            REFERENCIAS <Typography sx={optionalLabelStyle}> (Opcional)</Typography>
                        </FormLabel>
                        <TextField
                            inputProps={{...inputStyle, maxLength: 25 }}
                            placeholder='Escribe tus referencias'
                            size='small'
                            name='references'
                            value={references}
                            disabled={editingCfdi}
                            onChange={updateForm}
                        />
                        <Typography sx={{color: '#A1A1A1', fontSize: '0.875rem', pt:'0.4rem', lineHeight: {xs: '0.875rem', md: '1rem'}}}>*25 caracteres como máximo</Typography>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel sx={formLabelStyle}>
                            USO DE CFDI
                        </FormLabel>
                        <TextField
                            error={errors.cfdiUse}
                            inputProps={{...inputStyle, maxLength: 36 }}
                            placeholder='Usos de CFDI para RIF'
                            size='small'
                            name='cfdiUse'
                            value={userForm.cfdiUse}
                            onChange={updateForm}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel sx={{...formLabelStyle, paddingBottom: !file ? '0.625rem': '0rem'}}>
                            CONSTANCIA DE SITUACIÓN FISCAL (CSF)<Typography sx={optionalLabelStyle}> (Opcional)</Typography>
                        </FormLabel>
                        {file && (
                            <Box sx={{ paddingBottom: '10px' }}>
                                <FileDownloader file={file}/>
                            </Box>
                        )}
                        {userForm.cfdiUploaded && !file && (
                            <Box sx={{ paddingBottom: '10px' }}>
                                <FileDownloader
                                    file={{ url: true, fileName: 'Constancia de situacion Fiscal' }}
                                    open={downloadCfdi}
                                />
                            </Box>
                        )}
                        <>
                            <input type="file" style={{display:'none'}} ref={inputRef} accept="application/pdf" onChange={handleFileChange} />
                            <Button 
                                sx={{ 
                                    fontSize: '0.8125rem',
                                    fontWeight:'700',
                                    background: '#FFF',
                                    color: '#FC581F',
                                    textTransform:'none',
                                    minWidth: {xs: '64px',lg: '64px'},
                                    minHeight: {xs: '44px',lg: '44px'},
                                    maxWidth: {xs: '200px',lg: '200px'},
                                    p:{xs:' 0.688rem 0.688rem', lg:'6px 16px'},
                                    borderRadius:'62.5rem',
                                    boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)',
                                    '& .MuiButton-startIcon':{
                                        marginRight: {xs:'0', lg: '8px'},
                                        marginLeft: {xs:'0', lg: '0px'},
                                    },
                                    '&:hover': { 
                                        backgroundColor: '#FAFAFA'
                                    },
                                    // display:{ xs:'none', md:'inline-flex' },
                                }}
                                variant="contained" 
                                startIcon={<AddIcon sx={{fontSize:'1.5rem !important'}} /> }
                                onClick={handleLabelClick}
                            >
                                <Typography sx={{ display:{xs:'block', lg:'block'}, fontSize: '0.8125rem', fontWeight:'700' }}>
                                  Agregar archivo PDF
                                </Typography>
                            </Button>
                            </>
                    </FormControl>
                </Grid>
                
            </Grid>
            <Box sx={{ pt:'2rem', pb:'1rem', display: 'flex', justifyContent: 'center', px:{xs:'2.63rem', md:'3.44rem', lg:'9.44rem' } }}>
                <ButtonStyled text={editingUser ? ' Guardar cambios' : 'Crear cuenta'}  handleClick={OnSubmitForm} customStyle={{padding: {xs: '1rem 2rem'}, width:'100%' }}/>
            </Box>
        </Box>
    </>
  )
}

export default RegisterPage