import { useContext, useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { Box, Divider, IconButton, Typography } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import Footer from "../components/Footer";
import { getProductById } from '../services/productService';
import ButtonStyled from '../components/ButtonStyled';
import FileDownloader from '../components/FileDownloader';
import { addItemToCart } from '../utils/utils';
import { UserContext, UserDispatchContext } from '../context/UserContext';
import { useSnackbar } from 'notistack';
import { userCartAddItem } from '../services/userCartService';
import Carousel from 'react-material-ui-carousel';

const subtitleStyle = {
    fontSize: {xs: '1.125rem', lg: '1.25rem'},
    fontWeight: '700',
    lineHeight: {xs: '1.25rem', lg: '1.5rem'},
    pb: '1rem'
}

const bulletItemStyle = {
    fontSize: {xs: '0.875rem'},
    lineHeight: {xs: '1rem', lg: '1.125rem'},
}

const BrandsContent = ({brands, customStyle, navigate}) => {
    return (
        <Box sx={customStyle}>
            <Typography sx={subtitleStyle}>
                Marca
            </Typography>
            <Divider sx={{borderColor: '#FEDED2', borderWidth: '1px' }}/>
            <Box
                sx={{
                    display: 'flex',
                    gap: '1.5rem',
                    flexWrap: 'wrap',
                    pt: '1.5rem'
                }}
            >
                {
                    brands.map((item, indx) => (
                        <Box key={indx} sx={{
                            display: 'flex',
                            padding: '1rem 1.5rem',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '0.5rem',
                            borderRadius: '0.5rem',
                            background: '#FFF',
                            border: '1px solid #BABABA',
                            '&:hover': {
                                cursor: 'pointer',
                                color: '#FC581F',
                            }
                        }}
                        onClick={() => navigate(`/products/brand/${item}`)}
                        >
                            <Typography sx={{ fontSize:{xs: '0.875rem', lg:'1rem'}, lineHeight: {xs: '1.125rem', lg: '1.25rem'}, textAlign: 'center' }}>
                                {item}
                            </Typography>
                        </Box>
                    ))

                }
            </Box>
        </Box>
    );
};

const ProductDetailsPage = () => {
    const { domain } = useContext(UserContext);
    const { state, pathname } = useLocation();
    const globalUserDispatchContext = useContext(UserDispatchContext);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [product, setProduct] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    

    useEffect(() => {
        getData();
    }, [])
    
    const getData = async () => {
        setIsLoading(true);
        const routeSpliced = pathname.split('/');
        const productId = routeSpliced[routeSpliced.length-1];
        const result = await getProductById(productId);
        if(!result.Product || !Object.keys(result.Product).length){
            navigate(`${pathname}/not-found`);
            return;
        }
        setProduct(result.Product);
        setIsLoading(false);
    };

    const addToCart = async (product, redirect = false) => {
        const newCart = addItemToCart(product);
        try {
            const userId = localStorage.getItem('UID');
            if(userId && !isLoading){
                setIsLoading(true);
                const result = await userCartAddItem(newCart);
                setIsLoading(false);
                if(result.result){
                    if(!newCart._id){
                        newCart._id = result.userCartId;
                    }
                    if(!redirect){
                        enqueueSnackbar(result.message, {
                            variant: 'success'
                        });
                    }
                }else {
                    enqueueSnackbar(result.message, {
                        variant: 'error'
                    });
                    return;
                }
            }else if(!redirect){
                enqueueSnackbar('articulo agregado al carrito.', {
                    variant: 'success'
                });
            }
            globalUserDispatchContext({
                type: 'ADD',
                userCart: newCart
            });
            if(redirect){
                navigate('/cart');
            }
        } catch (error) {
            console.log(error);
            enqueueSnackbar('ocurrio un problema al agregar al carrito', {
                variant: 'error'
            });
        }
    };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { xs: "2rem", md: "1.25rem", lg: "3rem" },
          pt: {
            xs: "6.1825rem",
            sm: "6.5575rem",
            md: "6.4975rem",
            lg: "8.75rem",
          },
          px: { xs: "1.25rem", md: "3rem", lg: "6.28rem" },
          pb: {xs: "3.25rem", md: '3rem', lg: '3.12rem'},
        }}
      >
        <Box key={'parte superior'} sx={{display:'flex', flexDirection: {xs: 'column', lg: 'row'}, gap: {xs: '1.5rem', lg:'3.25rem'} }}>
            <Box sx={{display: 'flex', flexDirection: 'column', width:{lg: '61.4%'}, gap: {xs: '1.19rem', md: '1.5rem', lg: '3rem'}}}>
                <Box sx={{ display: "flex", alignItems: 'flex-start' }}>
                    <IconButton
                        sx={{ padding: 0, pt: {xs:'0.25rem', md: '0.14rem'} }}
                        onClick={() => {
                        navigate(-1);
                        }}
                    >
                        <NavigateNextIcon
                        sx={{
                            transform: "rotate(180deg)",
                            ml: { xs: '-0.5rem', md: '-0.625rem' },
                            fontSize: { xs: "1.5rem", md: "2rem" },
                            color: "#FC581F",
                        }}
                        />
                    </IconButton>
                    <Typography
                        sx={{
                        color: "#303030",
                        fontSize: { xs: "1.25rem", md: "1.5rem" },
                        fontWeight: "bold",
                        }}
                    >
                        {!!product && product.productName}
                    </Typography>
                </Box>
                <Typography sx={{fontSize: {xs: '0.75rem', md: '0.875rem', lg: '1rem'}, fontWeight: {xs: '300', md: '400'}, lineHeight: {xs: '0.875rem', md: '1.125rem', lg: '1.25rem' }, maxHeight: {xs: '6.25rem', md: '7rem', lg: '6.4rem'}, height:{lg:'6.4rem'} , overflow:'auto' }}>
                    {!!product && product.description}
                </Typography>
                <BrandsContent brands={!!product ? product.brandsSubcategories : []} customStyle={{display: {xs: 'none', lg: 'block'  }}} navigate={navigate} />
            </Box>
            <Box sx={{display: 'flex', width:{lg: '38.6%'}, flexDirection: 'column', gap: {xs: '1.5rem', lg: '2rem'}, px: {md: '7.19rem', lg: '0rem'} }}>
                <Box sx={{ display: 'flex', justifyContent: 'center'}}>
                    <Box sx={{padding: '1.875rem', borderRadius: '0.5rem', border: '1px solid #BABABA', background: '#FFF' }}>
                        <Box sx={{width: {xs:'8.75rem', md: '15rem' }, height: {xs:'8.75rem', md: '15rem' }, display:'flex', justifyContent:'center', alignItems: 'center' }}>
                            { (!!product && !!product.images.length) &&
                                <Carousel
                                    sx={{width: {xs:'8.75rem', md: '15rem' }, height: {xs:'10.75rem', md: '17rem' }}}
                                    indicators={true}
                                    stopAutoPlayOnHover={true}
                                    animation='slide'
                                    indicatorContainerProps={{style: { display:'flex', position:'absolute', zIndex:2, bottom:'0%', justifyContent: 'center'}}}
                                    indicatorIconButtonProps={{style: {color: 'black'}}}
                                    activeIndicatorIconButtonProps={{style:{color: '#F23002'}}}
                                >
                                    {
                                        product.images.map( (item, i) => (
                                            <Box key={i} sx={{ width: {xs:'8.75rem', md: '15rem' }, height: {xs:'8.75rem', md: '15rem' }, display:'flex', justifyContent:'center', alignItems: 'center' }}>
                                                <img
                                                    style={{
                                                        objectFit:'contain',
                                                        height:'inherit',
                                                        width:'inherit'
                                                    }}
                                                    alt={`img ${i+1}`}
                                                    loading="lazy"
                                                    src={`${domain}${item.url}`}
                                                    onError={(e) => {
                                                        e.target.style.display = 'none';
                                                        e.target.nextSibling.style.display = 'flex'
                                                    }}
                                                />
                                                <Box sx={{display: 'none', alignItems:'center', justifyContent:'center', height:'100%'}}>
                                                    <Typography sx={{fontSize: {xs:13, md:16, textAlign: 'center'} }}>{`imagen ${i+1}`}</Typography>
                                                </Box>
                                            </Box>
                                        ))
                                    }
                                </Carousel>
                            }
                            {
                                <Box sx={{display: !!product && !!product.images.length ? 'none' : 'flex' , alignItems:'center', justifyContent:'center', height:'100%'}}>
                                    <Typography sx={{fontSize: {xs:13, md:16, textAlign: 'center'} }}>Sin imagen</Typography>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: {xs: 'column', md: 'row'}, px: {xs: '2.81rem', md: '0rem'}, gap: {xs: '1.5rem', md:'1.88rem', lg: '1.5rem'} }}>
                    {
                        !!product && 
                        (product?.productVariants?.length
                            ? <NavLink
                                style={{ textDecoration: 'none', width: { md: '50%', xs: '30%', sm: "20%" } }}
                                to={`/products/customize/${product._id}`}
                            >
                                <ButtonStyled
                                    isDisabled={isLoading}
                                    primary={true}
                                    text={
                                        <Box
                                            style={{
                                                margin: 0,
                                                fontSize: {
                                                    xs:'0.875rem',
                                                    md: '1rem'
                                                }
                                            }}
                                        >
                                            Personalizar producto &
                                            <br/>
                                            <Typography
                                                style={{
                                                    fontSize: {
                                                        xs:'1.875rem',
                                                        md: '1.25rem'
                                                    }
                                                }}
                                            >
                                                <strong>Agregar al carrito</strong>
                                            </Typography>
                                        </Box>
                                    }
                                    customStyle={{order: {xs: 2, md: 1}, padding: "0.75rem 1rem", width: '100%', fontWeight: '500', lineHeight:{xs: '1rem', md:'1.125rem'}, letterSpacing: '0.01563rem', height: '-webkit-fill-available' }}
                                />
                            </NavLink>
                            : <ButtonStyled
                                isDisabled={isLoading}
                                primary={false}
                                text={"Agregar al carrito"}
                                handleClick={() => addToCart(product)}
                                customStyle={{order: {xs: 2, md: 1}, padding: "0.75rem 1rem", border: '1px solid #303030', background: 'inherit', width: '100%', fontSize:{xs:'0.875rem', md: '1rem'}, lineHeight:{xs: '1rem', md:'1.125rem'}, letterSpacing: '0.01563rem' }}
                            />
                        )
                            
                         
                    }
                    {
                        (!!product && !!product.productVariants) && !product.productVariants.length && (
                            <ButtonStyled
                            isDisabled={isLoading}
                            text={"Revisar el carrito"}
                            handleClick={() => addToCart(product, true)}
                            customStyle={{ order: {xs: 1, md: 2}, padding: "0.75rem 1rem", width: '100%', fontSize:{xs:'0.875rem', md: '1rem'}, lineHeight:{xs: '1rem', md:'1.125rem'}, letterSpacing: '0.01563rem', height: '-webkit-fill-available' }}
                        />
                        )
                    }
                </Box>
            </Box>
        </Box>
        <BrandsContent brands={!!product ? product.brandsSubcategories : []} customStyle={{display: {xs: 'block', lg: 'none'  }}}/>
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: { md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)'},
                gridTemplateRows: { md: 'repeat(2, 1fr)', lg: '1'}, gap: {xs: '1.5rem'}
            }}>
            <Box sx={{width: {md: '100%', lg: '100%'} }}>
                <Typography sx={subtitleStyle}>
                    Aplicaciones
                </Typography>
                <Divider sx={{borderColor: '#FEDED2', borderWidth: '1px' }}/>
                <Box sx={{ pt: '1.5rem' }}>
                    <ul style={{marginBlockStart: 0, marginBlockEnd: 0, paddingInlineStart: '20px' }}>
                        {
                            !!product && product.applicationsSubcategories.map((item, indx) => (
                                <Typography key={indx} component={'li'} sx={bulletItemStyle}>
                                    {item}
                                </Typography>
                            ))

                        }
                    </ul>
                </Box>
            </Box>
            <Box sx={{width: {md: '100%', lg: '100%'} }}>
                <Typography sx={subtitleStyle}>
                    Magnitudes
                </Typography>
                <Divider sx={{borderColor: '#FEDED2', borderWidth: '1px' }}/>
                <Box sx={{ pt: '1.5rem' }}>
                    <ul style={{marginBlockStart: 0, marginBlockEnd: 0, paddingInlineStart: '20px' }}>
                        {
                            !!product && product.measuresSubcategories.map((item, indx) => (
                                <Typography key={indx} component={'li'} sx={bulletItemStyle}>
                                    {item}
                                </Typography>
                            ))
                        }
                    </ul>
                </Box>
            </Box>
            <Box sx={{width: {md: '100%', lg: '100%'} }}>
                <Typography sx={subtitleStyle}>
                    Características
                </Typography>
                <Divider sx={{borderColor: '#FEDED2', borderWidth: '1px' }}/>
                <Box sx={{ pt: '1.5rem' }}>
                    <ul style={{marginBlockStart: 0, marginBlockEnd: 0, paddingInlineStart: '20px' }}>
                        {
                            !!product && product.characteristics?.sort((a, b) => Number(a.orderId) - Number(b.orderId)).map((item, indx) => (
                                <Typography key={'characteristics-' + indx} component={'li'} sx={bulletItemStyle}>
                                    {item.name}
                                </Typography>
                            ))
                        }
                    </ul>
                </Box>
            </Box>
            <Box sx={{width: {md: '100%', lg: '100%'} }}>
                <Typography sx={subtitleStyle}>
                    Documentos
                </Typography>
                <Divider sx={{borderColor: '#FEDED2', borderWidth: '1px' }}/>
                <Box sx={{ pt: '1.5rem', gap: '1.25rem', display: 'flex', flexDirection: 'column' }}>
                    {
                        !!product && product.files.map((item, indx) => (
                            <FileDownloader key={indx} file={item} />
                        ))
                    }
                </Box>
            </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default ProductDetailsPage