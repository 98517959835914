import axios from '../utils/axios';

const SERVICE_URL = '/productInfoRequest';


export const ProductInfoRequestCreate = async (
    data
) => {
    try {
        let response = await axios.post(`${SERVICE_URL}/create`, data );
        return response.data;
    } catch (error) {
        return {result: false, error};
    }
}
