import React from 'react'
import { Box, Typography } from "@mui/material";
import { currencyFormat, getBasePrice } from '../utils/utils';
import { UserContext } from '../context/UserContext';

const labelStyle = {fontSize:{xs: '0.75rem', lg: '0.875rem'}};

const OrderProduct = ({orderItem, dollarPrice}) => {
  const { domain } = React.useContext(UserContext);

  return (
    <Box sx={{display: 'flex', gap: {xs: '1.25rem', md: '1rem'}, minWidth:{xs: '100%', md: '16.1rem', lg:'18.9rem'}, justifyContent: {xs: 'center'} }}>
        <Box sx={{width: {xs:'4.375rem', md: '3rem', lg:'3.875rem'}, height: 'auto', display:'flex', justifyContent:'center', alignItems: 'center' }}>
            { !!orderItem.product.images.length &&
                <img
                    style={{
                        objectFit:'contain',
                        height:'inherit',
                        width:'inherit'
                    }}
                    alt=''
                    src={`${domain}${orderItem.product.images[0].url}`}
                    onError={(e) => {
                        e.target.style.display = 'none';
                        e.target.nextSibling.style.display = 'flex'
                    }}
                />
            }
            <Box sx={{display: !!orderItem.product.images.length ? 'none' : 'flex' , alignItems:'center', justifyContent:'center', height:'100%'}}>
                <Typography sx={{fontSize: {xs:13, md:16, textAlign: 'center'} }}>Sin imagen</Typography>
            </Box>
        </Box>
        <Box sx={{display:'flex', flexDirection: 'column', gap: '0.5rem'}}>
            <Typography sx={{fontSize:{xs: '0.75rem', lg: '0.875rem', fontWeight:'500'}}}>{orderItem.product.productName}</Typography>
            <Typography sx={{color: '#7C7B7B', fontSize:{xs: '0.75rem', lg: '0.875rem'} }}>{`Cantidad: ${orderItem.quantity}`}</Typography>
            <Typography sx={labelStyle}>{`Costo por unidad: ${currencyFormat(getBasePrice(orderItem.product, dollarPrice))} MXN `}</Typography>
            <Typography sx={labelStyle}>{`Costo total: ${currencyFormat(getBasePrice(orderItem.product, dollarPrice) * orderItem.quantity)} MXN`}</Typography>
        </Box>
    </Box>
  )
}

export default OrderProduct