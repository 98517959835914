import {useState, useEffect, useContext } from 'react'
import { Box, FormControl, FormLabel, TextField, Container, Typography, Select, MenuItem, Button, IconButton } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../services/userService';

import Footer from '../components/Footer';
import { MexicanStates } from '../utils/enums';
import { useSnackbar } from 'notistack';
import { UserContext } from '../context/UserContext';
import ProductResume from '../components/ProductResume';
import { ProductInfoRequestCreate } from '../services/productInfoRequestService';
import { exchangeRatesByCode } from '../services/ExchangeRateService';

const styles = {
  stackGrid: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem'
  },
  title: {
    display:{ xs: 'none', md:'block'},
    color: '#303030',
    fontSize: { md:'1.5625rem' },
    fontWeight: 700,
  }
}

const formLabelStyle = {color: '#A1A1A1', fontWeight:'bold', fontSize:{xs: '0.875rem'}, display: 'flex' };
const inputStyle = { sx: { background: '#FFF', fontSize:{ xs: '0.9375rem' } }};
const sectionStyle = { paddingBottom:{xs: '1.88rem'}, paddingTop: {xs: '1.88rem'}, px:{ xs:'1.56rem'}};
const divStyle = { paddingBottom:{xs: '1.25rem'} };
const requiredLabelStyle = {color: '#FC581F', fontWeight:'bold'};

const defaultErrorValues = {clientNameError: false, emailError: false, phoneError: false};
const requiredValues = ['clientName', 'email', 'phone'];

const errorValues = {
  clientNameError: 'Nombre', 
  emailError: 'Email', 
  phoneError: 'Teléfono'
};

const RequestProductPage = () => {
  const globalUserContext = useContext(UserContext);
  const [dollarPrice, setDollarPrice] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseForm, setPurchaseForm] = useState({
    clientName: '',
    company: '',
    email: '',
    phone: '',
    region: '',
    comments: '',

  });
  const [userCart, setUserCart] = useState({items:[]});
  const [errors, setErrors] = useState(defaultErrorValues);
  
  const { clientName, company, email, phone, region, comments  } = purchaseForm;
  const {clientNameError, emailError, phoneError } = errors;


  useEffect(() => {
    setData();
    getExchangeRateByCode()
  }, [globalUserContext])

  const setData = async () => {
    const cartStorage = JSON.parse(localStorage.getItem('userCart')) || null;
    if(!cartStorage) {
        navigate('/');
    }
    setUserCart(cartStorage);
    const userId = localStorage.getItem('UID') || null;
    if(userId){
      const response = await getUser(userId);
      if(!!response.user){
        setPurchaseForm({
          clientName: `${response.user.name} ${response.user.lastName}`,
          company: '',
          email: response.user.email,
          phone: response.user.phone,
          region: response.user.region,
          comments: ''
        })
      }
    }
  };

  const OnGoBack = () =>{
    navigate(-1);
  }

  const getExchangeRateByCode = async () => {
    setIsLoading(true);
    let { message, result, exchangeRate } = await exchangeRatesByCode("USD");
    if (result) {
      setDollarPrice(exchangeRate.exchangeRate);
      setIsLoading(false);
    } else {
        setDollarPrice(0);
        enqueueSnackbar(message, {
            variant: "error",
        });
    }
  };

  const OnSubmitForm = async () => {
    if(isLoading || !validateForm()){
      return;
    }
    
    setIsLoading(true);
    const userId = localStorage.getItem('UID') || '';
    const resultForm = await ProductInfoRequestCreate({form: purchaseForm, products: getProductsOnly(userCart), userId: userId });
    setIsLoading(false);
    if(resultForm.result){
        enqueueSnackbar("Envio Exitoso.", {
          variant: 'success',
          style: { 
            Width: '100%',
            justifyContent: 'center'
          }
        });
        navigate('/');
    }else{
      enqueueSnackbar(`Ocurrio un error al enviar`, {
        variant: 'error'
      });
    }
  };

  const getProductsOnly = (userCart) =>{
    return userCart.items.map(item => ({
      productName: item.product.productName,
      sku: item.sku,
      quantity: item.quantity
    }))
  }

  const validateForm = () =>{
    let newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    requiredValues.forEach( key => {
      if(key == 'email'){
        newErrors[`${key}Error`] = !purchaseForm[`${key}`] || !emailRegex.test(purchaseForm[`${key}`]);
      } else if(key == 'phone'){
        newErrors[`${key}Error`] = !purchaseForm[`${key}`] || !purchaseForm[`${key}`].length == 10;
      } else {
        newErrors[`${key}Error`] = !purchaseForm[`${key}`];
      }
    });

    setErrors({
        ...errors,
        ...newErrors
    });

    for (let key in newErrors) {
        if (newErrors[key]) {
          enqueueSnackbar(`campo ${errorValues[key]} incorrecto.`, {
            variant: 'error'
          });
          return false;
        }
    }
    return true;
  }

  const updateForm = (e) => {
    let inputName = e.target.name;
    let value =  e.target.value;

    if (inputName === 'phone' && !/^\d*$/.test(value)) {
      return;
  }

    let NewValue = {[inputName]: value}

    setPurchaseForm({
        ...purchaseForm,
        ...NewValue
    });
    
    errors[`${inputName}Error`] && setErrors({ ...errors, [`${inputName}Error`]: false });
  };


  return (
    <Box minHeight={'100vh'} display={'flex'} flexDirection={'column'} sx={{backgroundColor:'#F8F8F8'}}>
        <Container maxWidth="xl" sx={{ display:'flex', flexDirection:'column', flexGrow: 1, width:'100%', px:{xs:'1.25rem', md:'3.12rem', lg:'6.25rem'}, pb:{xs:'3rem'}, pt:{xs:'6.1825rem', sm: '6.5575rem', md: '6.4975rem', lg:'8.75rem' } }}>
          <Box sx={{width:'100%', display:'flex', flexDirection: {xs: 'column', md:'row'}, gap: {xs: '2rem', md:'1.5rem', lg:'1.88rem'} }}>
              <Box  
                sx={{
                  width: '100%', order:{xs: 1, md: 1},
                    ...styles.stackGrid
                }}
              >
                  <Box sx={{display:'flex', alignItems:'center'}}>
                    <IconButton sx={{padding:0}} onClick={OnGoBack}>
                      <NavigateNextIcon sx={{transform: 'rotate(180deg)', fontSize:{xs:'1.5rem', md:'2rem'}, color: '#FC581F'}}/>
                    </IconButton>
                    <Typography sx={{
                      color: '#303030',
                      fontSize: {xs: '1.25rem', md:'1.5rem' },
                      fontWeight:"bold",
                    }}>
                      Información de contacto
                    </Typography>
                  </Box>
                  <Box sx={{display:'flex', flexDirection:'column', gap:'1.25rem'}}>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel sx={formLabelStyle}>
                            NOMBRE <Typography sx={requiredLabelStyle}>*</Typography>
                        </FormLabel>
                        <TextField 
                            inputProps={{...inputStyle, maxLength: 70}}
                            error={clientNameError}
                            placeholder='Escribe tu nombre'
                            size='small'
                            name='clientName' 
                            value={clientName}
                            onChange={updateForm}
                        />
                    </FormControl>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel sx={formLabelStyle}>
                            EMPRESA
                        </FormLabel>
                        <TextField 
                            inputProps={{...inputStyle, maxLength: 70}}
                            placeholder='Escribe tu empresa'
                            size='small'
                            name='company' 
                            value={company}
                            onChange={updateForm}
                        />
                    </FormControl>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel sx={formLabelStyle}>
                            EMAIL <Typography sx={requiredLabelStyle}>*</Typography>
                        </FormLabel>
                        <TextField 
                            inputProps={{...inputStyle, maxLength: 70, type:"email"}}
                            error={emailError}
                            placeholder='Escribe tu correo electrónico'
                            size='small'
                            helperText={emailError ? "ejemplo: correo@ejemplo.com": ''}
                            name='email' 
                            value={email}
                            onChange={updateForm}
                        />
                    </FormControl>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel sx={formLabelStyle}>
                            TELÉFONO <Typography sx={requiredLabelStyle}>*</Typography>
                        </FormLabel>
                        <TextField 
                            inputProps={{...inputStyle, maxLength: 10, type:"tel", pattern:"[0-9]{3}-[0-9]{2}-[0-9]{3}"}}
                            error={phoneError}
                            placeholder='Escribe tu teléfono'
                            size='small'
                            name='phone' 
                            value={phone}
                            onChange={updateForm}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                      <FormLabel sx={{...formLabelStyle, textTransform:'uppercase'}}> ESTADO </FormLabel>
                      <Select
                          sx={ inputStyle.sx }
                          size='small'
                          value={region}
                          name='region'
                          // IconComponent={() => <NavigateNextIcon sx={{transform: 'rotate(90deg)', color: '#FC581F', fontSize: {xs:'1.7rem', md:'2rem'} }}/>}
                          onChange={updateForm}
                      >
                          {!!Object.keys(MexicanStates) && Object.values(MexicanStates).map( (mexicanState, indx) => (
                              <MenuItem sx={inputStyle.sx} key={indx} value={mexicanState}>{mexicanState}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{
                      '& .MuiFormControl-root': {background:'white' }
                      }}>
                      <FormLabel sx={formLabelStyle} > 
                        PREGUNTAS / COMENTARIOS
                      </FormLabel>
                      <TextField
                        multiline
                        rows={3}
                        placeholder='Déjanos un mensaje o una pregunta' 
                        inputProps={{...inputStyle, maxLength: 400}}
                        size='small'
                        name='comments' 
                        value={comments}
                        onChange={updateForm}
                      />
                    </FormControl>
                    <Typography sx={{color: '#A1A1A1', fontSize: '0.9375rem' }}>
                      Una vez que tengamos tu información un representante se pondrá en contacto contigo.
                    </Typography>
                  </Box>
              </Box>
              <Box  sx={{ width: '100%', order:{xs: 2, md: 2}, display:'flex', flexDirection:'column', gap: '1.5rem'  }} >
                <Box sx={{display:'flex', flexDirection:'column', gap: {xs: '1.5rem', md: '1.88rem'}}}>
                  {
                    userCart.items.map(item => (
                      <ProductResume key={item.sku} itemCart={item} dollarPrice={dollarPrice} />
                    ))
                  }
                </Box>
                <Button
                  variant='contained'
                  fullWidth
                  onClick={OnSubmitForm}
                  sx={{
                    fontSize: '1rem',
                    fontWeight:'bold',
                    backgroundColor: '#FC581F',
                    textTransform:'none',
                    padding: '0.75rem 1rem',
                    boxShadow: 0,lineHeight: '1.125rem',
                    letterSpacing: '0.01563rem',
                    '&:hover': { 
                      backgroundColor: '#FC581F'
                    }
                  }}
                >
                  Solicitar información
                </Button>
              </Box>
          </Box>
        </Container>
        <Footer/>
    </Box>
  )
}

export default RequestProductPage