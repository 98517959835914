import React from "react";

function BoxIcon() {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.26576 15.5569V9.39321L1.88075 6.27684V12.4405L7.26576 15.5569ZM8.64652 15.5569L14.0315 12.4405V6.26303L8.64652 9.39321V15.5569ZM7.26576 17.1461L1.19038 13.6428C0.974065 13.5184 0.804921 13.3503 0.682945 13.1384C0.560981 12.9265 0.5 12.6961 0.5 12.4474V5.5513C0.5 5.30256 0.560981 5.07223 0.682945 4.86032C0.804921 4.64842 0.974065 4.48028 1.19038 4.3559L7.26576 0.852583C7.48208 0.728205 7.7122 0.666016 7.95614 0.666016C8.20008 0.666016 8.43021 0.728205 8.64652 0.852583L14.7219 4.3559C14.9382 4.48028 15.1074 4.64842 15.2293 4.86032C15.3513 5.07223 15.4123 5.30256 15.4123 5.5513V12.4474C15.4123 12.6961 15.3513 12.9265 15.2293 13.1384C15.1074 13.3503 14.9382 13.5184 14.7219 13.6428L8.64652 17.1461C8.43021 17.2705 8.20008 17.3327 7.95614 17.3327C7.7122 17.3327 7.48208 17.2705 7.26576 17.1461ZM11.3252 6.2423L13.2652 5.1229L7.95614 2.04798L6.00926 3.17431L11.3252 6.2423ZM7.95614 8.21162L9.93065 7.05766L4.60777 3.98277L2.63326 5.1229L7.95614 8.21162Z"
        fill="#FC581F"
      />
    </svg>
  );
}

export default BoxIcon;
