import { useEffect } from 'react';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';


const MercadoPagoButton = ({preferenceId}) => {
    
    useEffect(() => {
      initMercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY, { locale: 'es-MX' });
    }, []);

    return (
      <div>
        <Wallet initialization={{preferenceId: preferenceId}} />
      </div>
    );
}

export default MercadoPagoButton;




