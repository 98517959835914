import axios from '../utils/axios';

const SERVICE_URL = '/shoppingCarts';


export const userCartAddItem = async (data) => {
    try {
        let response = await axios.put(`${SERVICE_URL}/add`, data );
        return response.data;
    } catch (error) {
        return {result: false, message: 'ocurrio un problema al agregar al carrito'};
    }
};
export const userCartUpdate = async (data) => {
    try {
        let response = await axios.put(`${SERVICE_URL}/update`, data );
        return response.data;
    } catch (error) {
        return {result: false, message: 'ocurrio un problema en el carrito.'};
    }
};
export const userCartCreatePreference = async (data) => {
    try {
        let response = await axios.post(`${SERVICE_URL}/createpreference`, data );
        return response.data;
    } catch (error) {
        return {result: false, message: 'ocurrio un problema al crear el link de pago.'};
    }
};

export const getQuoteShipping = async (id, userCart = {}, buyingAsGuest = false) => {
    try {
        let response = await axios.post(`${SERVICE_URL}/quoteshipping`, { id, userCart, buyingAsGuest } );
        return response.data;
    } catch (error) {
        return {result: false, quote: [], message: 'ocurrio un problema al cotizar el envío.'};
    }
};

export const validateProductStockQuoted = async (quotedProducts, userCartId) => {
    try {
        let response = await axios.post(`${SERVICE_URL}/validateProductStockQuoted`, { quotedProducts, userCartId } );
        return response.data;
    } catch (error) {
        return {result: false, canFulfillOrder: false, message: 'ocurrio un problema al verificar stock.'};
    }
};

export const updateInvoicePreference = (userCartId, invoiceRequested) => {
    return axios.post(`${SERVICE_URL}/update/invoice-preference`, { id: userCartId, invoiceRequested } );
};

export const getCartById = (userCartId) => {
    return axios.get(`${SERVICE_URL}/${userCartId}`);
};

export const cartBuyAgain = (oldCartId) => {
    return axios.post(`${SERVICE_URL}/buy-again`, { oldCartId });
};

export const getStockByItemsCart = async (userCart, buyingAsGuest = false) => {
    try {
        let response = await axios.post(`${SERVICE_URL}/stockByItemsCart`, { userCart, buyingAsGuest } );
        return response.data;
    } catch (error) {
        return {result: false, itemsCartStock: [], message: 'ocurrio un problema al obtener el stock.'};
    }
};
