import { useNavigate } from 'react-router-dom';

import { Box, Toolbar, Typography } from '@mui/material';
import Footer from '../components/Footer';
import LogoWhite from '../components/Icons/LogoWhite';
import ButtonStyled from '../components/ButtonStyled';
import bgimg from '../assets/bgimg.jpg'
import SearchBar from '../components/SearchBar';


export default function HomePage() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/products`);
    }

    return (
        <Box minHeight={'100vh'} display={'flex'} flexDirection={'column'} sx={{backgroundImage: `url(${bgimg})`, backgroundPosition: {xs: '80% 0%', md: '50% 0%', lg: '0% 0%'}, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
            <Toolbar disableGutters sx={{ minHeight:{xs:'49px', md:'55px', lg:'88px'} }}/>
            <Box flexGrow={1} sx={{ display: 'flex', flexDirection:'column', justifyContent: 'center', px: {xs: '1.5rem', md:'5.19rem', lg:'6.25rem'}, background: 'RGBA(0,0,0,0.5)', 
            }}>
                <Box sx={{display: 'flex', flexDirection:'column', alignItems: {xs:'center', lg:'initial'}, width: {xs:'100%', lg:'37.875rem'} }}>
                    <Box sx={{display: {lg: 'none'}}}>
                        <LogoWhite/>
                    </Box>
                    <Typography sx={{fontSize:{xs: '1.75rem', md: '2.875rem'}, pt:'3.25rem', lineHeight: '3.5rem', color: 'white', pb:'1rem' }}> Más allá de la medición.</Typography>
                    <Box sx={{ height: '0.125rem', width: '9.375rem', backgroundColor: '#FC581F' }}/>
                    <Typography sx={{ fontSize: {xs: '1.375rem', md: '2.375rem'}, textAlign: {xs: 'center', lg: 'initial'}, color: 'white', pt:'1.5rem' }}>Ofrecemos productos de calidad y soluciones a medida.</Typography>
                    <Box sx={{width: '100%', pt:'2rem', pb: '3.25rem'}}>
                        <SearchBar isLarge={true}/>
                    </Box>
                    <Box sx={{paddingBottom: '1rem'}}>
                        <ButtonStyled text={'Ver el listado de productos'} handleClick={handleClick}/>
                    </Box>

                </Box>
                
            </Box>
            <Footer/>
        </Box>
    )
}
